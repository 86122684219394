import {
    Device, OpenVidu,  Publisher,
    PublisherEventMap,  Session, SessionEventMap,
    StreamManagerEventMap, Subscriber
} from 'openvidu-browser'
import { UseCheckProps } from './useCheck'
import { SetUserModelType } from '../../../models/user-model';

interface InitSessionReturn {
    OV: OpenVidu,
    session: Session,
}

interface ConnectParams extends InitSessionReturn {
    token: string | any,
}

export interface UseConnectProps extends UseCheckProps {
    sendSignalUserChanged: any,
    updateSubscribers: any,
    subscribeToUserChanged: any,
    subscribeToStreamDestroyed: any,
    subscribeToStreamCreated: any,
    
    setLocalUser: SetUserModelType,
    getToken: any,
}


type OnAudioChangeEventType = SessionEventMap | PublisherEventMap | StreamManagerEventMap | any



export const setVoiceHandler = (
    publisher: Publisher | Subscriber | any,
    userName: string,
) => {
    
    const getUserBlock = (): HTMLDivElement | null => {
        return document.querySelector(`#room-participant-${userName} .userIcon`)
    }
    const getSoundIcon = (): HTMLDivElement | null => {
        return document.querySelector(`#room-participant-${userName} .voice-sound.icon`)
    }
    
    publisher.on('streamAudioVolumeChange', (event: OnAudioChangeEventType): any => {
        const userBlock = getUserBlock()

        const newValue = event.value.newValue + 100
        
        if (userBlock)
            userBlock.style.border = `${newValue < 20 ? 0: newValue}px solid gray`
    });

    publisher.on('publisherStartSpeaking', () => {
        const soundIcon = getSoundIcon()
        const isSpeaking = soundIcon?.classList.contains("speaking")
        if (!isSpeaking)
            soundIcon?.classList.add("speaking")
    });
    
    publisher.on('publisherStopSpeaking', () => {
        const userBlock = getUserBlock()
        userBlock!.style.border = `0px solid gray`

        const soundIcon = getSoundIcon()
        
        const isSpeaking = soundIcon?.classList.contains("speaking")
        if (isSpeaking)
            soundIcon?.classList.remove("speaking")
    });
    
}




export default function useConnect({
    options,
    setOptions,

    userData,
    setUserData,

    sendSignalUserChanged,
    updateLayout,

    localUser,
    setLocalUser,
    
    updateSubscribers,
    subscribeToUserChanged,
    subscribeToStreamDestroyed,
    subscribeToStreamCreated,

    getToken,
    
    ...props
}: UseConnectProps) {
    
    const initSession = (): InitSessionReturn => {
        const OV = new OpenVidu();
        const session = OV.initSession()

        const payload = {
            OV,
            session
        }
        setUserData(prev => ({...prev, ...payload }))

        return payload
    }

    const subscribeSession = (
        callback: (initData: InitSessionReturn) => void
    ) => {
        const initData = initSession()
        const {session} = initData

        subscribeToStreamCreated(session);
        callback(initData)
    }

    const connectWebCam = async ({
        OV,
        session,
    }: InitSessionReturn) => {
        if (!OV || !session)
            return console.error("(OV, session) not defined for connectWebCam()")

        if (!(session.capabilities?.publish) || !(session.connection.connectionId)) {
            console.error("not publish or connectionId for connectWebCam")
            return
        }
        
        await OV.getUserMedia({ audioSource: undefined, videoSource: undefined });
        const devices = await OV.getDevices();
        const videoDevices: Device[] = devices.filter(device => device.kind === 'videoinput');

        const {videoActive, audioActive} = localUser

        const publisher = OV.initPublisher(undefined, {
            audioSource: undefined,
            videoSource: videoDevices[0].deviceId,
            publishAudio: audioActive,
            publishVideo: videoActive,
            resolution: '640x480',
            frameRate: 30,
            insertMode: 'APPEND',
        });


        setTimeout(() => {
            if (session.capabilities.publish) {
                publisher.on('accessAllowed', () => {
                    session.publish(publisher)
                    .then(() => {
                        setUserData(prev => {
                            const {subscribers: prevSubs} = prev
                            const updatedSubs = updateSubscribers(session, prevSubs);
                            
                            setOptions(p => ({...p, localUserAccessAllowed: true}))
                            return {...prev, subscribers: updatedSubs}
                        })
                    })
                });
            }
            
            subscribeToUserChanged(session);
            subscribeToStreamDestroyed(session);
            
        }, 1000)
        
        const isScreenShareActive = false
        
        setLocalUser(user => ({
            ...user,
            nickname: userData.userName,
            connectionId: session.connection.connectionId,
            screenShareActive: isScreenShareActive,
            streamManager: publisher,
        }))
        
        setUserData(pred => {
            const currentVideoDevice = videoDevices[0]
            return {...pred, currentVideoDevice }
        })
        
        sendSignalUserChanged({ isScreenShareActive }, session);
        publisher.on('streamPlaying', () => {
            updateLayout();
            const {video}: {video: HTMLElement} = publisher.videos[0]
            const {classList} = video.parentElement as HTMLElement
            classList.remove('custom-class');

            
        });

        setVoiceHandler(publisher, userData.userName)
    }



    const connect = ({
        OV,
        token,
        session,
    }: ConnectParams) => {
        const {userName: clientData} = userData
        
        
        console.log(`token: "${token}", userName (clientData): "${clientData}"`)
        
        session
            .connect(token, { clientData })
            .then(() => {
                console.log("SESSION_CONNECTED")
                connectWebCam({ session, OV, });
            })
            .catch(error => {
                console.error("Error connecting to the session: ", error);
            });
    }


    const connectToSession = ({
        OV,
        session,
    }: InitSessionReturn) => {
        const {
            sessionId,
            role,
            token: existingToken,
        } = userData

        const connectNow = (token: string) => {
            connect({
                token,
                OV,
                session,
            })
        }

        if (existingToken)
            return connectNow(existingToken)

        getToken({sessionId, role})
        .then((token: string) => {
            setUserData(prev => ({...prev, token}))
            connectNow(token)
        })
    }

    const joinSession = () => {
        subscribeSession((initData) => connectToSession(initData))
    }
    

    return {
        connectWebCam,
        connect,
        connectToSession,
        joinSession,

    }
}