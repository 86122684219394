import { Publisher } from "openvidu-browser";
import { useState } from "react";
import UserRoles, { UserRoleType } from "../enum/user_roles";

export type StreamManagerType = Publisher | null | any

type UserModelType = "local" | 'remote'


interface UserWithActions {
    kick?: () => void,
    muteForAll?: () => void,
    toggleFilter?: () => void,
}

interface UserModel extends UserWithActions {
    connectionId: string;
    nickname: string,

    audioActive: boolean;
    videoActive: boolean;
    screenShareActive: boolean;
    isAdmin: boolean;
    mutedForAll: boolean,
    
    streamManager: StreamManagerType;
    type: UserModelType;
}


export const getDefaultUserData = (
    type: UserModelType,
    isAdmin: boolean,
): UserModel => {
    return {
        connectionId: "",
        streamManager: null,

        audioActive: false,
        videoActive: true,
        screenShareActive: false,
        mutedForAll: false,
        isAdmin,
        
        nickname: "",
        type,
    }
}

export type SetUserModelType = React.Dispatch<React.SetStateAction<UserModel>>

export const useUserModel = (
    type: UserModelType,
    isAdmin: boolean,

    fullUserData: UserModel | null = null,
): [UserModel, SetUserModelType] => {
    const startUserData = fullUserData || getDefaultUserData(type, isAdmin)
    const [user, setUser] = useState<UserModel>(startUserData)

    return [user, setUser]
}

export const userIsAdmin = (
    role: UserRoleType,
): boolean => {
    const isAdmin = role === UserRoles.MODERATOR
    return isAdmin
}


export default UserModel;
