
export function textCutter(
    text: string,
    maxLength = 20
): string {
    const {length} = text
    const shouldCut = length > maxLength
    const cuttedText = text.slice(0, maxLength-4) + '....'
    const currentText = shouldCut ? cuttedText: text

    return  currentText
}