import { useMemo} from 'react'
import OpenViduLayout from "../../../layout/openvidu-layout"
import { useUserModel, userIsAdmin } from "../../../models/user-model"
import useChat from "./useChat"
import useCheck from "./useCheck"
import useConnect from "./useConnect"
import useLayout from "./useLayout"
import useMedia from "./useMedia"
import useRecording from "./useRecording"
import useScreenShare from "./useScreenShare"
import useSession from "./useSession"
import useSubscribe from "./useSubscribe"

import { VideoRoomOptionsProps } from "../../video-room/VideoRoom"
import { UserDataProps } from "../../App"

export interface FullVideoRoomProps extends VideoRoomOptionsProps, UserDataProps {
    fullLeave: any,
}

export default function useVideoRoom({
    userData,
    options,

    setUserData,
    setOptions,
    fullLeave,
}: FullVideoRoomProps) {
    const layout = new OpenViduLayout()
    
    const isAdmin = userIsAdmin(userData.role)
    const [localUser, setLocalUser] = useUserModel("local", isAdmin)
    
    const payload = {
        layout,
        localUser, setLocalUser,

        userData, setUserData,
        options, setOptions,
    } as any

    const {
        createToken,
        createSession,
        getToken,
    } = useSession(payload)

    const {
        updateLayout,
    } = useLayout(payload)

    const {
        toggleChat,
    } = useChat({ ...payload, updateLayout})
    
    const {
        checkSomeoneShareScreen,
        sendSignalUserChanged,
        checkNotification,
        checkSize,
        closeDialogExtension,
        nicknameChanged,
    } = useCheck({...payload, updateLayout, toggleChat,})

    const {
        startRecording,
        stopRecording,
    } = useRecording(payload)

    const {
        switchCamera,
        toggleFullscreen,
        camStatusChanged,
        micStatusChanged,
    } = useMedia({ ...payload, sendSignalUserChanged })
    
    const {
        updateSubscribers,
        subscribeToStreamCreated,
        deleteSubscriber,
        subscribeToStreamDestroyed,
        subscribeToUserChanged,
    } = useSubscribe({
        ...payload,

        updateLayout,
        fullLeave,
        sendSignalUserChanged,
        checkSomeoneShareScreen,
    })

    const {
        connectWebCam,
        connect,
        connectToSession,
        joinSession,

    } = useConnect({
        ...payload,

        sendSignalUserChanged,
        updateLayout,

        updateSubscribers,
        subscribeToUserChanged,
        subscribeToStreamDestroyed,
        subscribeToStreamCreated,
        
        getToken,
    })

    const {
        stopScreenShare,
        screenShare,
    } = useScreenShare({
        ...payload,
        sendSignalUserChanged,
        updateLayout,
        connectWebCam,
    })


    return {
        layout,
        localUser, setLocalUser,
        updateLayout,
        checkSize,
        joinSession,

        switchCamera,
        toggleChat,
        
        screenShare,
        stopScreenShare,
        camStatusChanged,
        micStatusChanged,
        toggleFullscreen,
        startRecording,
        stopRecording,
        closeDialogExtension,
        nicknameChanged,
        checkNotification,
        sendSignalUserChanged,
    }
}