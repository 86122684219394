import React, { useContext, useEffect, useState } from 'react';

import './App.scss';

import { AuthForm, AuthBackground } from './auth';
import VideoRoom from './video-room/VideoRoom';
import { Device, OpenVidu, Session } from 'openvidu-browser';
import UserModel from '../models/user-model';
import SessionItems from '../enum/session_items';
import UserRoles from '../enum/user_roles';
import { DEFAULT_SESSION_ID } from '../config';


interface RecordingParams {
    id: string,
    sessionId: string,
    createdAt: number,
    url: string,

    hasAudio: boolean,
    hasVideo: boolean,

    size: number,
    duration: number,

    
    object: string,
    name: string,
    outputMode: "INDIVIDUAL" | string,
    status: string,
}

export type SessionIdType = string;

export type UserDataType = {
    sessionId: SessionIdType,
    userName: string,

    subscribers: UserModel[],
    recordings: RecordingParams[],
    role: string,

    authed: boolean,
    chatDisplay: "none" | 'block',

    filterUsername: string,
    filterData: object,
    filterSocket: WebSocket| undefined,
    
    currentVideoDevice: Device | undefined,
    
    recordingId: string | undefined,
    session: Session | undefined,
    token: string | undefined,
    OV: OpenVidu |  undefined,

    isFilterTurnedOn: (user: UserModel) => boolean,
};
export type SetUserDataType = React.Dispatch<React.SetStateAction<UserDataType>>


export interface UserDataProps {
    userData: UserDataType,
    setUserData: SetUserDataType,
}


function generateUserName() {
    return 'OpenVidu_User_' + Math.floor(Math.random() * 100)
}

function getDefaultUserData(): UserDataType {
    const sessionId = localStorage.getItem(SessionItems.SESSION_ID) || DEFAULT_SESSION_ID
    const userName = localStorage.getItem(SessionItems.USER_NAME) || ""

    return {
        sessionId,
        userName,

        subscribers: [],
        recordings: [],
        role: UserRoles.PUBLISHER,
        filterUsername: "",
        filterData: {},
        filterSocket: undefined,

        authed: false,
        chatDisplay: "none",
        
        currentVideoDevice: undefined,
        recordingId: undefined,
        session: undefined,
        token: undefined,
        OV: undefined,

        isFilterTurnedOn(user) {
            return user.nickname === this.filterUsername
        },
    }
}
var defaultUserData = getDefaultUserData()
export type UserDataStateType = [
    UserDataType,
    React.Dispatch<React.SetStateAction<UserDataType>> | null
]
var UserDataContext = React.createContext<UserDataStateType>([defaultUserData, null])




export function useUserData(): UserDataStateType {
    var [userData, setUserData] = useContext(UserDataContext)
    
    return [userData, setUserData]
}

export default function App({
    ...props
}) {
    
    const [userData, setUserData] = useState<UserDataType>(defaultUserData)

    const leaveSession = () => {
        setUserData(prev => {
            const {session} = prev
        
            if (session)
                session.disconnect();
            
            const defaultUserData = getDefaultUserData()
            return defaultUserData
        })
    }

    const onBeforeLoad = () => {
        leaveSession();
    }
    const resetBrowserSession = () => {
        localStorage.removeItem(SessionItems.SESSION_ID)
        window.history.pushState({}, "", `${location.origin}/`)
    }

    const fullLeave = () => {
        resetBrowserSession();
        leaveSession();
    }

    useEffect(() => {
        window.addEventListener('beforeunload', onBeforeLoad);

        return () => {
            window.removeEventListener('beforeunload', onBeforeLoad);
        }
    }, [])

    const {authed} = userData
    
    return (
        <UserDataContext.Provider value={[userData, setUserData]}>
            <div className="container">
                {
                    authed
                    ? <VideoRoom
                        {...{
                            userData,
                            setUserData,
                            leaveSession,
                            generateUserName,
                            fullLeave
                        }}
                    />
                    : (
                        <AuthBackground>
                            <AuthForm
                                {...{
                                    userData,
                                    setUserData
                                }}
                            />
                        </AuthBackground>
                    )
                }

            </div>
        </UserDataContext.Provider>
    );   
}
