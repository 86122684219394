import React, { useEffect, useRef, useState, KeyboardEvent, ChangeEvent } from 'react';
import Fab from '@material-ui/core/Fab';
import Send from '@material-ui/icons/Send';

import './ChatComponent.scss';
import { Tooltip } from '@material-ui/core';
import UserModel, { SetUserModelType, StreamManagerType } from '../../models/user-model';
import { SessionIdType, UserDataType } from '../App';
import { SignalEvent } from 'openvidu-browser';
import LoadingBar from '../../base/loading-bar/LoadingBar';


export interface ChatMessageParams {
    connectionId: string,
    nickname: string,
    message: string
}

export interface ChatComponentProps {
    localUser: UserModel,
    setLocalUser: SetUserModelType,

    close: any,
    messageReceived: any,
    userData: UserDataType,
    setNewMessageCount: React.Dispatch<React.SetStateAction<number>>,
}

export default function ChatComponent({
    localUser,
    setLocalUser,

    close,
    messageReceived,

    setNewMessageCount,
    userData,
    ...props

}: ChatComponentProps) {
    const [messageList, setMessageList] = useState<ChatMessageParams[]>([])
    const [message, setMessage] = useState("")
    
    const [userConnectionId, setUserConnectionId] = useState(userData.session?.connection?.connectionId)
    const chatScroll = useRef<HTMLDivElement>(null)
    const chatClosed = userData.chatDisplay === 'none'

    
    const messageListPush = (message: ChatMessageParams) => {
        setMessageList(prev => ([...prev, message]))
    }

    const closeChat = () => {
        close(undefined);
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {target} = event
        setMessage(target.value)
    }

    const getCurrentMessage = (): string => {
        const currentMessage = message.replace(/ +(?= )/g, '');
        return currentMessage.trim()
    }

    const sendMessage = () => {
        if (!message)
            return console.error("!message")

        const { streamManager } = localUser
        const {session} = userData
        
        if (!streamManager)
            return console.error("streaManager is undefined for sendMessage()")
        if (!session)
            return console.error("session is undefined for sendMessage()")

        const currentMessage = getCurrentMessage()
        
        const notAcceptMessages = ['']
        const messageAccepted = !(notAcceptMessages.includes(currentMessage))

        const updatedConnectionId = session.connection?.connectionId
        if (!updatedConnectionId)
            return console.error("connectionId is undefined for sendMessage()")

        if (updatedConnectionId !== userConnectionId)
            setUserConnectionId(updatedConnectionId)

        if (messageAccepted) {
            const nickname = userData.userName
            const payload = { message: currentMessage, nickname, connectionId: updatedConnectionId }

            session.signal({
                data: JSON.stringify(payload),
                type: 'chat',
            });
        }
        
        setMessage("")
    }

    const handlePressKey = (event: KeyboardEvent<HTMLInputElement>) => {
        const {key} = event
        if (key === 'Enter') {
            sendMessage();
        }
    }

    

    const scrollToBottom = () => {
        const {current} = chatScroll
        if (!current)
            return

        setTimeout(() => {
            try {
                current.scrollTop = current.scrollHeight;
            } catch (err) {
                console.error(err)
            }
        }, 20);
    }

    const chatHandler = (event: SignalEvent) => {
        const {data, from} = event
        const connectionId = from?.connectionId

        if (!connectionId)
            return console.error("connectionId is undefined for useEffect of <ChatComponent />")
        if (!data)
            return console.error("data is undefined for useEffect of <ChatComponent />")

        const newMessageData: ChatMessageParams = JSON.parse(data);
        
        messageListPush(newMessageData)

        setNewMessageCount(prev => {
            if (!chatClosed) {
                return 0
            }
            return prev + 1
        })

        scrollToBottom()
    }

    useEffect(() => {
        const { session } = userData
        
        session?.on('signal:chat', chatHandler)
        return () => {
            session?.off('signal:chat', chatHandler)
        }
    }, [userData])
    
    const styleChat = {
        display: userData.chatDisplay,
        width: chatClosed ? "0 !important": '',
        height: chatClosed ? "0 !important": '',
     }

    return (
        <div
            id="chat-overlay"
            style={styleChat}
            
        >
            <div
                id="chatComponent"
                style={styleChat}
            >
                <div id="chatToolbar">
                    <span className="title">
                        Chat
                    </span>
                </div>
                <div
                    className="message-wrap"
                    ref={chatScroll}
                >
                    {
                        messageList.map((messageData: ChatMessageParams, i) => {
                            const {connectionId, message, nickname} = messageData
                            const sameUser = connectionId === userConnectionId
                            const messageClassName = `message ${sameUser ? 'right': 'left'}`

                            return (
                                <div
                                    key={i}
                                    id="remoteUsers"
                                    className={messageClassName}
                                >
                                    <div className="msg-detail">
                                        <div className="msg-info">
                                            <p>{nickname}</p>
                                        </div>

                                        <div className="msg-content">
                                            <span className="triangle" />
                                            <p className="text">
                                                {message}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div id="messageInput">
                    <input
                        placeholder="Send a message"
                        id="chatInput"
                        value={message}
                        onChange={handleChange}
                        onKeyDown={(event) => {
                            const {streamManager} = localUser
                            if (streamManager)
                                handlePressKey(event)
                        }}
                        spellCheck={false}
                        onContextMenu={() => false}
                    />
                    <Tooltip title="Send message">
                        <Fab
                            size="small"
                            id="sendButton"
                            onClick={() => {
                                const {streamManager} = localUser
                                if (streamManager)
                                    sendMessage()
                            }}
                        >
                            {
                                localUser.streamManager
                                ? <Send />
                                : <LoadingBar />
                            }
                        </Fab>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
}
