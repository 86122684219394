import React from 'react';

import './AuthForm.scss'
import { Button, FormInput } from '../../built-in';

import { UserDataProps, } from '../../App';
import { StandartEventType } from '../../types';
import SessionItems from '../../../enum/session_items';
import UserRoles from '../../../enum/user_roles';


export default function AuthForm({
    userData,
    setUserData,
    ...props
}: UserDataProps) {
    const handleChangeSessionId = ({target}: StandartEventType) => {
        setUserData((prev) => {
            const sessionId = target.value
            localStorage.setItem(SessionItems.SESSION_ID, sessionId)
            return {...prev, sessionId }
        })
    }

    const handleChangeUserName = ({target}: StandartEventType) => {
        setUserData((prev) => {
            const userName = target.value
            localStorage.setItem(SessionItems.USER_NAME, userName)
            return {...prev, userName}
        })    
    }

    const authenticate = ({asAdmin}: {asAdmin: boolean}) => {
        setUserData(prev => {
            const { sessionId } = prev
            if (!sessionId) {
                alert("Enter right room name")
                return prev
            }

            const {MODERATOR, PUBLISHER} = UserRoles
            const role = asAdmin ? MODERATOR: PUBLISHER
            
            const newUserData = {
                ...prev,
                authed: true,
                role,
            }

            const url = `${location.origin}#${sessionId}`
            window.history.pushState({}, "", url)

            return newUserData
        })   
    }

    var authAsAdmin = () => authenticate({asAdmin: true})

    const {userName, sessionId} = userData
    
    return (
        <form className="auth-form">
            <p className="title">Join a video session</p>
            <p className="subtitle">Choose your username and conference</p>
            <FormInput
                label='Your name'
                type="text"
                value={userName}
                onChange={handleChangeUserName}
                onKeyDown={(event) => {
                    if (event.key === "Enter")
                        authAsAdmin()
                }}
                required
            />
            <FormInput
                label="Room name"
                type="text"
                value={sessionId}
                onChange={handleChangeSessionId}
                required
            />
            
            <Button
                className="submit"
                type="button"
                onClick={() => authenticate({asAdmin: false})}
            >
                Join
            </Button>
            <Button
                className="submit"
                type="button"
                onClick={authAsAdmin}
            >
                Join as Admin
            </Button>
        </form>
    )
}
