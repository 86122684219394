import AuthAnimation from '../auth-animation/AuthAnimation'
import './AuthBackground.scss'
import React from 'react'


export default function AuthBackground({
    ...props
}) {
    return <div
        className="auth-background"
    >
        <AuthAnimation />
        {props.children}
    </div>
}