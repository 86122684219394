import React, { useEffect, useState } from "react"
import { LineChart, Tooltip, } from "recharts"
import UserModel from "../../../../models/user-model"
import { Publisher } from "openvidu-browser"


const MAX_POINTS = 20

export default function SoundChart({
    user,
    ...props
}: {
    user: UserModel,
}) {
        
    return <div
        style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '20px',
            width: "90%",
            height: '50px',
            borderRadius: '10px',
            outline: '1px solid white',
            backgroundColor: '#404040',
            cursor: 'pointer',
        }}
    ></div>
}