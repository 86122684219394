import React, { useRef, useState, useEffect, useMemo, ReactNode} from 'react';
import ContextMenu from '../context-menu/context-menu';

import ReactDOM, {Root} from 'react-dom/client';

interface Pos2d {
  x: number,
  y: number,
}

export type OnContextMenuFuncType = () => ReactNode;
export type OnContextMenuEventFuncType = (event: React.MouseEvent) => any

function useOnContextMenu(
  element: ReactNode
): OnContextMenuEventFuncType {
  const [visible, setVisible] = useState(false)
  const [pos, setPos] = useState<Pos2d>({x: 0, y: 0})

  const wrapperRef = useRef<HTMLElement>(document.createElement("div"))
  const root = useMemo(() =>
    ReactDOM.createRoot(wrapperRef.current),
    [wrapperRef, wrapperRef.current, () => wrapperRef.current]
  )

  var render = (jsx: ReactNode) => {
    root?.render(jsx)
  }
  
  useEffect(() => {
    var newWrapper = wrapperRef.current
    
    document.body.appendChild(newWrapper)
    render(<ContextMenu />)
    
  }, [])

  const closeContextMenu = () => {
    render(<ContextMenu />)
  }

  const handleCloseContextMenu = () => {
    closeContextMenu()
    
    document.removeEventListener('click', handleCloseContextMenu);
       
  };

  var onContextMenu: OnContextMenuEventFuncType = (
    event
  ) => {
    

    if (visible) {
      return
    }

    var {clientY: y, clientX: x} = event
    var newVisible = true
    var updatedPos = {x, y}

    render(
      <ContextMenu
        {...{
          visible: newVisible,
          ...updatedPos
        }}
      >
        {element}
      </ContextMenu>
    )
    setVisible(newVisible)
    setPos(updatedPos)

    event.preventDefault();
    document.addEventListener('click', handleCloseContextMenu);
    
  };

  return onContextMenu
}

export default useOnContextMenu;
