import { getOpenViduLayoutOptions } from "../../video-room/VideoRoom";
import { FullVideoRoomProps } from "./useVideoRoom";

import OpenViduLayout from "../../../layout/openvidu-layout"
import UserModel from "../../../models/user-model"
import { VoidFuncType } from "../../types";
import { ToggleChatFuncType, UseChatParams } from "./useChat";
import { UpdateLayoutFuncType } from "./useLayout";
import { SIGNAL_TYPES } from "../../../config";
import { Session, SignalEvent } from "openvidu-browser";



export interface UseCheckProps extends UseChatParams {
    layout: OpenViduLayout,
    localUser: UserModel,
    toggleChat: ToggleChatFuncType,
    setLocalUser: any,
}



export default function useCheck({
    userData, setUserData,
    localUser, setLocalUser,

    layout,
    
    updateLayout,
    setOptions,
    options,
    toggleChat,
    
    ...props
}: UseCheckProps) {
    
    const checkSomeoneShareScreen = (
        updatedSubscribers: UserModel[] | null =null
    ) => {
        const subscribers = updatedSubscribers || userData.subscribers
        const isScreenShared =
            subscribers.some((user: UserModel) => user.screenShareActive)
            || localUser.screenShareActive;
        
        const openviduLayoutOptions = getOpenViduLayoutOptions({fixedRatio: isScreenShared})
        layout.setLayoutOptions(openviduLayoutOptions);
        updateLayout();
    }

    const sendSignalUserChanged = (
        jsonData: object,
        fromSession:Session |any=null
    ) => {
        const session = fromSession || userData.session
        
        const data = JSON.stringify(jsonData)
        
        const signalOptions = { data, type: SIGNAL_TYPES.userChanged, }
        if (!session)
            return console.error("session is not undefined for session.signal()")
        
        session.signal(signalOptions);
    }


    const checkNotification = () => {
        const { chatDisplay } = userData
        const messageReceived = chatDisplay === 'none'
        setOptions(prev => ({...prev, messageReceived}))
    }

    const checkSize = () => {
        const {updated} = options
        const layoutElement = document.querySelector('#layout') as HTMLElement
        if (!layoutElement)
            return

        const {offsetWidth} = layoutElement

        const newUpdated = offsetWidth <= 700 && !updated

        if (newUpdated)
            toggleChat('none')
        
        setOptions(prevOptions => {
            return {...prevOptions, updated: newUpdated}
        })
    }

    const closeDialogExtension = () => {
        setOptions(prev => ({...prev, showExtensionDialog: false }))
    }

    const nicknameChanged = (nickname: string) => {
        setUserData(prev => ({...prev, userName: nickname}))
        sendSignalUserChanged({ nickname });
    }


    return {
        checkSomeoneShareScreen,
        sendSignalUserChanged,
        checkNotification,
        checkSize,
        closeDialogExtension,
        nicknameChanged,
    }
}