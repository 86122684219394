import React, { useCallback, useMemo } from "react"
import './ParticipantContextMenu.scss'



import IconButton from '@material-ui/core/IconButton';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import VolumeOff from '@material-ui/icons/VolumeOff';
import VolumeUp from '@material-ui/icons/VolumeUp';

import UserModel from "../../../models/user-model";
import { StreamOptionsType } from "../StreamComponent";
import { textCutter } from "../../../base/funcs/text";
import { SetUserDataType, useUserData } from "../../App";
import { getUserActionByUser, useUserAction, useUserActionContext } from "../../video-room/video-peers-block/VideoPeersBlock";

interface ParticipantContextMenuProps {
    user: UserModel,
    mode: boolean,
    setStreamOptions: React.Dispatch<React.SetStateAction<StreamOptionsType>>,
    streamOptions: StreamOptionsType,
    sendSignalUserChanged: any,
    isVideoDefined: boolean,
}



export default function ParticipantContextMenu({
    user,
    
    mode,
    setStreamOptions,
    streamOptions,
    sendSignalUserChanged,
    isVideoDefined,
    
    ...props
}: ParticipantContextMenuProps) {
    var [userData] = useUserData()
    var toggleSound = () => {
        setStreamOptions(prev => ({...prev, mutedSound: !(prev.mutedSound)}))
    }

    var isLocal = user.type === "local"
    var isRemote = !isLocal
    
    var {mutedSound} = streamOptions
    var nickname = (isLocal ? userData.userName: user.nickname) || "Unknown"
    
    var textNickname = textCutter(nickname)
    
    return <div
        className='participantContextMenu'
        id="#context-menu"
    >
        <p
            className="nickname"
        >
            {textNickname}
        </p>
        <ul className="action-buttons">
            {
                (isRemote && isVideoDefined) &&
                <li
                    onClick={toggleSound}
                >
                    <IconButton
                        className="volume icon"
                        
                    >
                        {
                            mutedSound
                            ? <VolumeOff />
                            : <VolumeUp />
                        }
                    </IconButton>
                    <span>
                        {
                            mutedSound
                            ? "Unmute"
                            : "Mute"
                        }
                    </span>
                </li>
            }
        </ul>
    </div>
}
