import { Request } from "../../../base";

interface SessionParams {
    sessionId: string | number,
    role?: string
}


export default function useSession({
    ...props
}) {
    

    const createSession = async ({
        sessionId
    }: SessionParams) => {
        const {data: newSessionId} = await Request.post({
            url: 'server/sessions',
            body: { customSessionId: sessionId }
        })
        
        return newSessionId;
    }

    const createToken = async ({
        sessionId,
        role,
    }: SessionParams) => {
        const {data: token} = await Request.post({
            url: `server/sessions/${sessionId}/connections`,
            body: { role }
        })
        
        return token
    }

    const getToken = async ({
        sessionId,
        role,
    }: SessionParams) => {
        const newSessionId = await createSession({sessionId});
        const token = await createToken({sessionId: newSessionId, role})
        return token
    }

    

    return {
        createToken,
        createSession,
        getToken,
    }
}