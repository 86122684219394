import React, { HTMLAttributes, ReactNode } from 'react';
import { useState } from 'react';
import './dropdown.scss'

const Element = ({
    children,
}: HTMLAttributes<HTMLElement>) => {
    return <>{children}</>
}

interface DropdownProps extends React.HTMLAttributes<HTMLElement>{
    buttonElement: ReactNode, 
    children?: ReactNode,
    onOpen?: null | (() => void),
    onClose?: null | (() => void) ,
    isMouse?: boolean,
    WrapButtonElement?: React.ReactNode | any,
}

function Dropdown({
    buttonElement,
    isMouse=false,
    children=null,
    onOpen=null,
    onClose=null,
    WrapButtonElement=Element,
    ...props
}: DropdownProps) {

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(prev => {
            const newValue = !prev
            if (newValue && onOpen)
                onOpen()
            else if (!newValue && onClose)
                onClose()
            return newValue
        })
    };
    const closeDropdown = () => {
        setIsOpen(prev => {
            if (onClose)
                onClose()
            return false
        });
    };

    const button = <button
        onClick={toggleDropdown}
        onMouseEnter={(isMouse && toggleDropdown as any ) || null}
        // onMouseLeave={(isMouse && closeDropdown as any ) || null}
        className={`button-element`}
    >
        {buttonElement}
    </button>

    
    return (
        <div
            className={`dropdown-container ${isOpen ? "opened": ""}`}
            {...props}
        >
            {
                WrapButtonElement
                ? <WrapButtonElement>
                    {button}
                </WrapButtonElement>
                : button
            }

            {isOpen && (
                <div
                    onMouseLeave={(isMouse && closeDropdown as any ) || null}
                    className="dropdown-content"
                    onBlur={closeDropdown}
                    tabIndex={0}
                >
                    {children}
                </div>
            )}
        </div>
    );
}

export default Dropdown;
