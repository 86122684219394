import OpenviduApi from "../../../api/OpenviduApi"
import { Request } from "../../../base"
import { UseConnectProps } from "./useConnect"



export default function useRecording({
    userData,
    setUserData,
    
    ...props
}: UseConnectProps) {

    const startRecording = async () => {
        try {
            const {sessionId} = userData
            if (!sessionId) {
                console.error("not sessionId for startRecording()")   
                return false
            }

            const {data} = await Request.post({
                url: '/server/recordings/start',
                body: {
                    session: sessionId, 
                    outputMode: "INDIVIDUAL"
                },
            })

            const recordingId = data
            setUserData(prev => ({...prev, recordingId}))
            return true
        } catch(error) {
            console.error("ERROR_START_RECORDING")
            return false
        }
    }

    const stopRecording = async () => {
        try {
            const { recordingId } = userData
            if (!recordingId) {
                console.error("not recordingId for stopRecording()")
                return false
            }
        
            const {data: newRecordingId} = await Request.post({
                url: `/server/recordings/stop/${recordingId}`
            })

            const newRecordingData = await OpenviduApi.getRecordingById(newRecordingId)
            
            setUserData(prev => ({
                ...prev,
                recordingId: newRecordingId,
                recordings: [...prev.recordings, newRecordingData],
            }))
            return true
        }
        catch(error) {
            console.error("ERROR_STOP_RECORDING")
            return false
        }
    }

    return {
        startRecording,
        stopRecording,
    }
}