import React, { Dispatch, SetStateAction, } from 'react';


interface AlertContextProps {
    alertText: string,
    setAlertText: null | Dispatch<SetStateAction<string>>,
    visible: boolean,
    setVisible: null | Dispatch<SetStateAction<boolean>>,
}

const AlertContext = React.createContext<AlertContextProps>({
    alertText: "",
    setAlertText: null,
    visible: false,
    setVisible: null,
})

export default AlertContext;

