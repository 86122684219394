import React, { useEffect, HTMLAttributes, useState, useMemo } from "react";
import styles from './AllUsersBlock.module.scss'
import UserModel from "../../../../models/user-model";
import { Button, Input } from "../../../built-in";
import { useUserData } from "../../../App";
import UserRoles from "../../../../enum/user_roles";

import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import KickIcon from "../../../../base/svg/kick";

import VolumeOff from '@material-ui/icons/VolumeOff';
import VolumeUp from '@material-ui/icons/VolumeUp';
import { UserActionFuncType, getUserActionByUser, useUserAction, useUserActionContext } from "../VideoPeersBlock";
import UserWithFilter from "../UserWithFilter/UserWIthFilter";




interface AllUsersBlockProps extends HTMLAttributes<HTMLElement> {
    updateLayout: any,
    users: UserModel[],
}

function AllUsersBlock({
    children,
    users,
    updateLayout,
    ...props
}: AllUsersBlockProps) {
    var [searchUsername, setSearchUsername] = useState("")
    var [userData, setUserData] = useUserData()

    useEffect(() => {
        setTimeout(updateLayout, 1000)
        
        return () => {
            setTimeout(updateLayout, 1000)
        }
    }, [])
    
    var shownUsers = useMemo(() => {
        
        if (searchUsername === "")
            return users
            
        return (
            users
            .filter((user) => {
                return user.nickname
                    .includes(searchUsername)
                    
            })
        )
    }, [users, searchUsername])

    return (
        <div
            className={styles.AllUsersBlock}

            {...props}
        >
            <h1
                className={styles.title}
            >
                Participants
            </h1>

            <form
                className={styles.usersBlockForm}
                onSubmit={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    return false
                }}
            >
                <Button
                    className={styles.addButton}
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        return false
                    }}
                >
                    Add
                </Button>
                <Input
                    placeholder="Search by name"
                    className={styles.searchParticipantInput}
                    onChange={(event) => {
                        setSearchUsername(event.target.value.toLowerCase())
                    }}
                >
                </Input>
            </form>

            
            <div
                className={styles.usersBlock}
            >
                {shownUsers.map(
                    (user, index) => {
                        var localIsAdmin = userData.role === UserRoles.MODERATOR
                        var isRemoteUser = userData.userName !== user.nickname
                        return (
                            <div
                                key={index}
                                className={styles.userBlock}
                            >
                                <p>{user.nickname}</p>
                                {!isRemoteUser && <p
                                    style={{
                                        marginLeft: 'auto',
                                        marginRight: '10px',
                                        color: '#a0a0a0'
                                    }}
                                >(Me)</p>}

                                {
                                    (
                                        localIsAdmin &&
                                        isRemoteUser
                                    ) &&
                                    <div
                                        className={styles.actionButtons}
                                    >
                                        <button
                                            onClick={user.muteForAll}
                                            
                                        >
                                            {
                                                user.mutedForAll
                                                ? <VolumeOff/>
                                                : <VolumeUp />
                                            }
                                        </button>
                                        <button
                                            onClick={user.kick}
                                        >
                                            {
                                                <KickIcon
                                                    side={25}
                                                />
                                            }
                                        </button>
                                        <button
                                            onClick={user.toggleFilter}
                                        >
                                            {
                                                userData.filterUsername === user.nickname
                                                ? <EmojiEmotionsIcon />
                                                : <InsertEmoticonIcon />
                                            }
                                        </button>
                                    </div>
                                }
                            </div>
                        )
                    }
                )}
            </div>
            
        </div>
    )
}

export default AllUsersBlock;
