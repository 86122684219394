import styles from './AlertModalWindow.module.scss';
import React, { HTMLAttributes, useEffect } from 'react';
import useAlertContext from './useAlertContext';



interface AlertModalWindowProps extends HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode,
    
}

function AlertModalWindow({
    children,
    ...props
}: AlertModalWindowProps) {
    const {setVisible, visible, alertText, setAlertText} = useAlertContext()
    
    
    function alert(message: string) {
        
        setVisible && setVisible(() => {
            setAlertText && setAlertText(message)
            return true
        })
    }

    useEffect(() => {
        window.alert = alert
    }, [])

    return (
        visible && <div
            className={styles.AlertModalWindow}
        >
            <div
                className={styles.contentModal}
            >
                <span className={styles.alertText}>{alertText}</span>
                <button
                    className={styles.okButton}
                    onClick={() => {
                        setVisible && setVisible(p => !p)
                        setAlertText && setAlertText("")
                    }}
                >
                    OK
                </button>

            </div>
            
        </div>
    )
}

export default AlertModalWindow;
export type {
    AlertModalWindowProps
}