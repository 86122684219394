import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import './button.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    children?: ReactNode;
}

export default function Button({
    className='',
    children,
    ...props
}: ButtonProps) {
    
    return <button
        className={`standart ${className}`}
        {...props}
    >
        {children}
    </button>
}
