import React, { useEffect, useMemo, useState } from "react";
import UserModel from "../../../../models/user-model";
import StreamComponent, { StreamOptionsType } from "../../../stream/StreamComponent";
import FilterInfo from "../FilterInfo/FilterInfo";
import LoadingBar from "../../../../base/loading-bar/LoadingBar";

interface UserWithFilterProps {
    payload: any,
    user: UserModel | undefined | null,
}


export default function UserWithFilter({
    payload,
    user,
}: UserWithFilterProps) {
    const [streamOptions, setStreamOptions] =  useState<StreamOptionsType>({
        analytics: false,
        mutedSound: false,
    })

    const generalStyle = useMemo(() => ({
        flex: '1',
    }), [])
    const streamOptionsState = [streamOptions, setStreamOptions]

    const updateLayout = () => {
        setTimeout(() => {
            payload.updateLayout()
        }, 2000)
    }

    useEffect(() => {
        updateLayout()

        return () => {
            updateLayout()
        }
    }, [])

    if (!user)
        return <LoadingBar />

    const userPayload = {
        ...payload,
        user,
        style: generalStyle,
    }
    
    return <>
        <StreamComponent
            streamOptionsState={streamOptionsState}
            {...userPayload}
        />
        <FilterInfo
            {...userPayload}
        />
    </>
}