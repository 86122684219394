import { UseConnectProps, setVoiceHandler } from "./useConnect";



export interface UseScreenShareProps extends UseConnectProps {
    connectWebCam: any,
}

export default function useScreenShare({
    userData,

    localUser,
    setLocalUser,

    setOptions,
    sendSignalUserChanged,
    updateLayout,
    connectWebCam,
    
    ...props
}: UseScreenShareProps) {
    const screenShare = async (onErrorCallback: any) => {
        return await new Promise((resolve, reject) => {
            const {OV, session} = userData
        
            if (!OV || !session)
                return reject("DATA_IS_NOT_DEFINED for screenShare")
            
            const videoSource = navigator.userAgent.indexOf('Firefox') !== -1 ? 'window' : 'screen';
            try {
                

                const {videoActive, audioActive} = localUser
                const publisher = OV.initPublisher(
                    undefined,
                    {
                        videoSource: videoSource,
                        audioSource: videoSource,

                        publishAudio: audioActive,
                        publishVideo: videoActive,
                        mirror: false,
                    },
                    (error) => {
                        onErrorCallback()
                        
                        if (!error)
                            return reject("UNDEFINED_SCREEN_SHARE_ERROR")
                            
                        switch (error.name) {
                            case 'SCREEN_EXTENSION_NOT_INSTALLED':
                                setOptions(pred => ({...pred, showExtensionDialog: true}))
                                return reject('SCREEN_EXTENSION_NOT_INSTALLED')
                                
                            case 'SCREEN_SHARING_NOT_SUPPORTED':
                                return reject('Your browser does not support screen sharing')
                            case 'SCREEN_EXTENSION_DISABLED':
                                return reject('You need to enable screen sharing extension')
                            case 'SCREEN_CAPTURE_DENIED':
                                return reject('You need to choose a window or application to share')
                        }
                    },
                );
                
                publisher.once('accessAllowed', () => {
                    const {streamManager} = localUser
                    session.unpublish(streamManager);
                    
                    
                    session.publish(publisher)
                    .then(() => {

                        setLocalUser(prevLocalUser => {
                            sendSignalUserChanged({ isScreenShareActive: true }, session);
                            return {
                                ...prevLocalUser,
                                streamManager: publisher,
                                screenShareActive: true,
                            }
                        })
                    })
                    .catch(() => {
                        reject("ERROR_ACCESS_ALOWED_FOR_SCREEN_SHARE")
                    })
                });

                publisher.on('streamPlaying', () => {
                    updateLayout();
                    const {video}: {video: HTMLVideoElement} = publisher.videos[0]
                    const {classList} = video.parentElement as HTMLElement
                    classList.remove('custom-class');
                });
                setVoiceHandler(publisher, userData.userName)

                resolve(true)

            } catch(error) {
                return reject("SCREEN_SHARE_ERROR")                
                
            }
        })
    }


    const stopScreenShare = async () => {
        const {session, OV} = userData
        const {streamManager} = localUser
        
        if (!(session && OV)) {
            const errorData = JSON.stringify({
                session: Boolean(session),
                OV: Boolean(OV)
            })
            console.error(`Error! session some is undefined for stopScreenShare(): ${errorData}`)
            return false
        }
        if (!streamManager) {
            console.error("Error! streamManager is undefined for stopScreenShare()")
            return true
        }

        session.unpublish(streamManager)
        .then(() => {
            connectWebCam({
                session,
                OV,
            })
        })
        .catch((error) => {console.error(error)})
        
        return true
    }


    return {
        stopScreenShare,
        screenShare
    }
}