import { UpdateLayoutFuncType } from "./useLayout";
import { FullVideoRoomProps } from "./useVideoRoom";


export type ToggleChatFuncType = (
    property?: "block"| "none" | undefined
) => void;

export interface UseChatParams extends FullVideoRoomProps {
    updateLayout: UpdateLayoutFuncType,
}


export default function useChat({
    userData,
    options,

    setUserData,
    setOptions,
    updateLayout,
    ...props
}: UseChatParams) {
    
    const toggleChat: ToggleChatFuncType = (
        property,
    ) => {
        const { chatDisplay } = userData
        const display = property !== undefined
            ? property
            : (
                chatDisplay === 'none'
                ? 'block'
                : 'none'
            )
        setUserData(prev => {
            return {...prev, chatDisplay: display}
        })
        setTimeout(() => {
            setOptions(prevOptions => {
                const messageReceived = display === 'block' ? false: prevOptions.messageReceived
                return {
                    ...prevOptions,
                    messageReceived,
                }
            })
            updateLayout();
        }, 1)
        
    }

    return {
        toggleChat,

    }
}