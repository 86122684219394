import React from 'react';
import './voice_sound.scss';




export default function VoiceSoundIcon({
    ...props
}) {
    return <div
        {...props}
        className="voice-sound icon "
    >
        <div className="small line"></div>
        <div className="medium line"></div>
        <div className="main line"></div>
        <div className="medium line"></div>
        <div className="small line"></div>
    </div>
}