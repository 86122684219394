import React from 'react';


export default function UserIcon() {
    const fillColor = 'white'
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            opacity="0.5"
            width="200px" height="200px"
            viewBox="0 0 24 24" fill="none"
            className='userIcon'
            style={{
                borderRadius: '100%',
                transition: '.5s border',
            }}
        >
        <circle cx="12" cy="9" r="3" stroke={fillColor} strokeWidth="1.5"/>
        <circle cx="12" cy="12" r="10" stroke={fillColor} strokeWidth="1.5"/>
        <path 
            d="M17.9691 20C17.81 17.1085 16.9247 15 11.9999 15C7.07521 15 6.18991 17.1085 6.03076 20"
            stroke={fillColor} strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    )
}