import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import './VideoPeersBlock.scss'
import StreamComponent from '../../stream/StreamComponent'
import { SetUserDataType, UserDataType } from '../../App'
import { OptionsType } from '../VideoRoom'
import UserModel, { StreamManagerType } from '../../../models/user-model'
import FilterInfo from './FilterInfo/FilterInfo'
import UserWithFilter from './UserWithFilter/UserWIthFilter'
import { update } from 'plotly.js'
import AllUsersBlock from './AllUsersBlock/AllUsersBlock'
import RoomEvents from '../../../enum/room_events'
import { Request } from '../../../base'
import useFilter from '../../hooks/useFilter'
import { useRouteLoaderData } from 'react-router-dom'


export interface VideoPeersBlockProps {
    userStreamIsDefined: boolean,
    userData: UserDataType,
    setUserData: SetUserDataType,
    options: OptionsType,
    mode: boolean, // local user is admin
    localUser: UserModel,

    toggleChat: any,
    checkNotification: any,
    nicknameChanged: any,
    streamManager: StreamManagerType,
    sendSignalUserChanged: any,
    updateLayout: any,
}

export type UserActionFuncType = (user: UserModel) => void

interface UserActionContextProps {
    kickUser: UserActionFuncType | null,
    muteForAll: UserActionFuncType | null,
    toggleFilter: UserActionFuncType | null,
}

export var UserActionContext = React.createContext<UserActionContextProps>({
    kickUser: null,
    muteForAll: null,
    toggleFilter: null,
})

export var useUserActionContext = () => {
    var ctx = useContext(UserActionContext)
    return ctx
}

export var getUserActionByUser = (
    ctx: UserActionContextProps,
    user: UserModel
) => {
    var getFunc =
        (funcOrNull: UserActionFuncType | null | undefined)
        : () => void => {
            if (typeof funcOrNull === "function" )
                return () => (funcOrNull as any)(user)
            
            
            return () => {
                console.error("userActionFunc is not defined", funcOrNull)
            }
    }
    return {
        kick: getFunc(ctx.kickUser),
        muteForAll: getFunc(ctx.muteForAll),
        toggleFilter: getFunc(ctx.toggleFilter),
    }
}
export var useUserAction = (user: UserModel) => {
    var ctx = useUserActionContext()
    
    var commands = getUserActionByUser(ctx, user)
    return commands
}

export default function VideoPeersBlock({
    userStreamIsDefined,
    userData,
    setUserData,
    options,
    mode,
    localUser,
    
    toggleChat,
    checkNotification,
    nicknameChanged,
    streamManager,
    sendSignalUserChanged,
    updateLayout,

    
    ...props
}: VideoPeersBlockProps) {
    const layoutRef = useRef(null)
    var predFilterUsernameRef = useRef<string>(userData.filterUsername)

    var {removeFilter, applyFilter} = useFilter()

    var payload = {
        mode,
        
        sendSignalUserChanged,
        setUserData,
        updateLayout,
    }

    const {
        subscribers,
    } = userData

    var getUserByName = (nickname: string) => {
        var foundUser =
            subscribers
            .filter(u => u.nickname === nickname)
            .shift()
        return foundUser
    }
    

    const filterUser = useMemo<UserModel | undefined>(
        () => getUserByName(userData.filterUsername),
        [
            userData,
            () => userData.subscribers,
            () => userData.filterUsername
        ])

    

    useEffect(() => {
        var predFilterUsername = predFilterUsernameRef.current
        var predFilterUser = getUserByName(predFilterUsername)

        var newFilterUsername = ""

        if (predFilterUser === filterUser)
            return

        if (!filterUser && userData.filterUsername !== "") {
            setUserData(p => ({...p, filterUsername: ""}))
        }

        else if (filterUser && predFilterUsername !== filterUser.nickname) {
            applyFilter(filterUser)

            if (predFilterUsername !== "" && predFilterUser)
                removeFilter(predFilterUser)
            newFilterUsername = filterUser.nickname
        }

        else if (!filterUser && predFilterUser) {
            removeFilter(predFilterUser)
        }

        predFilterUsernameRef.current = newFilterUsername
        
    }, [filterUser])

    useEffect(() => {
        const layout = layoutRef.current as HTMLElement | any
        if (!layout) return

        layout.addEventListener("resize", updateLayout)
        return () => {
            layout.removeEventListener("resize", updateLayout)
        }
    }, [layoutRef, () => layoutRef.current, layoutRef.current])
    
    useEffect(() => {
        updateLayout()
    }, [filterUser])
    
    

    var UserActionInnerContext = useUserActionContext()
    
    var users: UserModel[] = useMemo(() => [
        {...localUser, nickname: userData.userName},
        ...userData.subscribers,
    ].map(u => {
        return {
            ...u,
            ...getUserActionByUser(UserActionInnerContext, u)
        }
    }), [UserActionInnerContext, localUser, () => userData.subscribers, userData])



    
    return (<>
        {
            (filterUser) &&
            <div
                className="filterBlock"
                style={options.allUsersShowed ?
                    {
                        overflowX: "hidden",
                        overflowY: "auto",
                        flexDirection: "column",
                        width: "400px",
                    }:
                    {}
                }
            >
                <UserWithFilter
                    user={filterUser}
                    payload={payload}
                />
            </div>
        }
        <div
            id="layout"
            ref={layoutRef}
            className={`bounds ${filterUser ? "withFilterBlock": ""}`}
            style={
                options.allUsersShowed
                ? {
                    left:
                        filterUser
                        ? '450px'
                        : "31.5%"
                    ,
                    width:
                        filterUser
                        ? "30%"
                        : "55%"
                    ,
                }
                : {}
            }
        >
            {
                users.map((sub, index) => {
                    if (sub.nickname === userData.filterUsername)
                        return null

                    return (
                        <StreamComponent
                            user={sub}
                            key={index}
                            keyValue={index}
                            {...payload}
                        />
                    )
                })
            }
        </div>
        {
            options.allUsersShowed &&
            <AllUsersBlock
                updateLayout={updateLayout}
                users={users}
            />
        }
    </>)
}
