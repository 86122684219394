// eslint-disable-next-line
import axios from "axios";
import { APPLICATION_SERVER_URL, IS_LOCAL } from '../config'


interface RequestParams {
    url: string,
    body?: object,
    config?: object,
}


class Request {
    static DEFAULT_CONFIG = {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    }
    static DEFAULT_DOMAIN = APPLICATION_SERVER_URL

    static getUrl(root: string): string {
        const domain = "https://" + Request.DEFAULT_DOMAIN
        const isRoot = root.startsWith("/")
        
        if (isRoot)
            return IS_LOCAL ? domain + root: root
        
        const isUrl = root.startsWith("http")
        if (isUrl)
            return root

        // api/.... (isLocation = true):
        const updatedRoot = `/${root}`
        if (!IS_LOCAL)
            return updatedRoot

        const url = domain + updatedRoot
        return url
    }

    static getWssUrl(path: string): string {
        const url = "wss://" + Request.DEFAULT_DOMAIN + path;
        return url;
    }

    static async post({
        url,
        body = {},
        config=Request.DEFAULT_CONFIG,
    }: RequestParams) {
        const rightUrl = Request.getUrl(url)
        const response = await axios.post(rightUrl, body, config)
        return response
    }


    static async get({
        url,
        config=Request.DEFAULT_CONFIG,
    }: RequestParams) {
        const rightUrl = Request.getUrl(url)
        const response = await axios.get(rightUrl, config)
        return response
    }

}

export default Request;
