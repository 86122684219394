import { Request } from "../base";
import { OPENVIDU_SECRET } from "../config";

const OPENVIDU_CREDENTIALS = `OPENVIDUAPP:${OPENVIDU_SECRET}`
const encodedCredentials = btoa(OPENVIDU_CREDENTIALS);


class OpenviduApi {

    static async getRecordingById(recordingId: string) {
        const url = `/openvidu/api/recordings/${recordingId}`
        const config = {
            headers: {
                "Authorization": `Basic ${encodedCredentials}`,
            },
        }

        const {data} = await Request.get({url, config,})
        return data
    }
    
}

export default OpenviduApi;
