import React, { useCallback, useEffect, useState, useMemo, useRef, } from 'react';
import './StreamComponent.scss';
import OvVideoComponent from './OvVideo';

import MicOff from '@material-ui/icons/MicOff';
import VideocamOff from '@material-ui/icons/VideocamOff';

import UserModel from '../../models/user-model';
import CrownIcon from '../../base/svg/crown';
import useOnContextMenu from '../../base/hooks/useContextMenu';
import ParticipantContextMenu from './ParticipantContextMenu/ParticipantContextMenu';
import { useUserData } from '../App';
import { useUserAction } from '../video-room/video-peers-block/VideoPeersBlock';



export type StreamOptionsStateType = [StreamOptionsType, React.Dispatch<React.SetStateAction<StreamOptionsType>>]
interface StreamComponentProps extends React.HTMLAttributes<HTMLDivElement> {
    user: UserModel,
    
    mode: boolean,
    sendSignalUserChanged: any,

    streamOptionsState?: StreamOptionsStateType | null,

    updateLayout: any,
    keyValue?: number,
}

export const isUserVideoDefined = (user: UserModel): boolean => {
    var streamId = user?.streamManager?.stream?.streamId
    
    var isDefined = Boolean(streamId)
    return isDefined
}

export interface StreamOptionsType {
    analytics: boolean,
    mutedSound: boolean,
}


export default function StreamComponent({
    user,
    mode, // is admin

    sendSignalUserChanged,

    keyValue=0,
    updateLayout,
    streamOptionsState=null,
    
    ...props
}: StreamComponentProps) {
    var [userData] = useUserData()
    const [streamOptions, setStreamOptions] =
        streamOptionsState ||
        useState<StreamOptionsType>({
            analytics: false,
            mutedSound: false,
        })
        
    var [isVideoDefined, setIsVideoDefined] = useState<boolean>(
        isUserVideoDefined(user)
    )
    useEffect(() => {
        updateLayout()
    }, [isVideoDefined]);
    
    var onContextMenu = useMemo(
        () => {
            var contextMenuJsx = <ParticipantContextMenu
                {...{
                    user,
                    mode,
                    streamOptions,
                    isVideoDefined,
                    filter: userData.isFilterTurnedOn(user),
    
                    setStreamOptions,
                    sendSignalUserChanged
                }}
            />
            var onContextMenu = useOnContextMenu(contextMenuJsx);
            return onContextMenu;
        },
        [
            () => userData.filterUsername,
            isVideoDefined, mode, user, streamOptions,
        ]);
    
    useEffect(() => {
        setIsVideoDefined(isUserVideoDefined(user))
    }, [user, ])
    
    var {
        mutedSound,
    } = streamOptions

    var {
        nickname,
        audioActive: isAudioActive,
        videoActive: isVideoActive,
        type,
        isAdmin,
    } = user 

    const isLocal = type === "local"
    
    return (
        <div
            {...props}
            id={`room-participant-${user.nickname}`}
            key={keyValue}
            className={
                `room-participant-component OT_root OT_publisher custom-class
                 ${isVideoDefined ? "": "loading"} ${props.className || ""}`
            }
            onContextMenu={onContextMenu}
        >
            {
                (
                    <div className="streamComponent">
                        <div
                            className="pointer participant_data"
                            style={{
                                opacity: isVideoDefined ? "1":'0.5',
                            }}
                        >
                            <span className="nickname">
                                {
                                    isLocal
                                    ? userData.userName || nickname
                                    : nickname
                                }
                            </span>
                            
                            <>
                                {
                                    !isVideoActive
                                    && (
                                        <div className="cam icon-wrap">
                                            <VideocamOff />
                                        </div>
                                    )
                                }
                                {
                                    !isAudioActive
                                    && (
                                        <div className="mic icon-wrap">
                                            <MicOff />
                                        </div>
                                    )
                                }
                                {
                                    isAdmin
                                    && (
                                        <div className="admin-crown icon-wrap">
                                            <CrownIcon />
                                        </div>
                                    )
                                }
                            </>
                        </div>
                        
                        {
                            <OvVideoComponent
                                {...{
                                    user,
                                    mutedSound,
                                    isVideoDefined,
                                    setIsVideoDefined,
                                    streamOptions,
                                    setStreamOptions,
                                }}
                            />
                        }
                        
                        
                    </div>
                )
            }
        </div>
    );
}
