import React, { useEffect, useRef, useState } from 'react';
import './Toolbar.scss';

import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import Fullscreen from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';
import SwitchVideoIcon from '@material-ui/icons/SwitchVideo';
import PictureInPicture from '@material-ui/icons/PictureInPicture';
import ScreenShare from '@material-ui/icons/ScreenShare';

import Tooltip from '@material-ui/core/Tooltip';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StopIcon from '@material-ui/icons/Stop';
import IconButton from '@material-ui/core/IconButton';
import { UseConnectProps } from '../hooks/useVideoRoom/useConnect';
import useDebounce from '../../base/hooks/useDebounce';
import ChatComponent from '../chat/ChatComponent';
import { isFullscrened } from '../../base/funcs/fullscreen';
import SavedRecordings from './SavedRecordings/SavedRecordings';
import UsersIcon from '../../base/svg/users';
import { OptionsType, SetOptionsType } from '../video-room/VideoRoom';


export interface ToolbarProps extends UseConnectProps {
    mode: boolean,
    options: OptionsType,
    setOptions: SetOptionsType,


    leaveSession: any,
    switchCamera: any,
    screenShare: any,
    stopScreenShare: any,
    camStatusChanged: any,
    micStatusChanged: any,
    showNotification: any,
    toggleFullscreen: any,
    startRecording: any,
    stopRecording: any,
    fullLeave: any,

    checkNotification: any,
    streamManager: any,
}

export default function Toolbar({
    userData,
    options,
    setOptions,

    localUser,
    setLocalUser,

    mode,

    switchCamera,
    leaveSession,
    toggleChat,

    screenShare,
    stopScreenShare,
    camStatusChanged,
    micStatusChanged,
    showNotification,
    toggleFullscreen,
    startRecording,
    stopRecording,
    fullLeave,

    checkNotification,
    streamManager,
    ...props
}: ToolbarProps) {
    
    const [mediaActive, setMediaActive] = useState({
        record: false,
        fullscreen: false,
    })
    const [unHovered, setUnHovered] = useState(true)
    const [newMessageCount, setNewMessageCount] = useState(0)
    
    
    const toolbarRef = useRef(null)

    const roomButtonsRef =  useRef<HTMLDivElement>(null)
    const extraButtonsRef = useRef<HTMLDivElement>(null)
    
    

    const callback = useDebounce(() => {
        setUnHovered(true)
    }, 2000)


    const toggleFullscreenMode = (): void => {
        toggleFullscreen()
    }
    const onMouseMove = () => {
        setUnHovered(false)

        callback()
    }
    
    useEffect(() => {
        const isOpened = userData.chatDisplay === "block"
        if (isOpened)
            setNewMessageCount(0)
    }, [userData])

    useEffect(() => {
        if (newMessageCount > 0)
            onMouseMove()
    }, [newMessageCount])
    
    useEffect(() => {
        const {body} = document
        

        const fullscreenChangeHandler = () => {
            const fullscreened = isFullscrened()
            setMediaActive(prev => ({...prev, fullscreen: !fullscreened}))
        };
        
        document.addEventListener('fullscreenchange', fullscreenChangeHandler);
        body.addEventListener("mousemove", onMouseMove)
        body.addEventListener("dblclick", toggleFullscreenMode)
        
        return () => {
            document.removeEventListener('fullscreenchange', fullscreenChangeHandler);
            body.removeEventListener("dblclick", toggleFullscreenMode)
            body.removeEventListener("mousemove", onMouseMove)
        }
    }, [])

    const chatOpened = userData.chatDisplay === "block"
    const {fullscreen, record, } = mediaActive
    
    return (
        <div
            ref={toolbarRef}
            id='toolbar-panel'
            className={`${unHovered ? 'unhovered': ""}`}
        >
            <Tooltip title="Users">
                <div
                    className="allUsersButton"
                    onClick={() => setOptions(p => ({...p, allUsersShowed: !p.allUsersShowed}))}
                >
                    <UsersIcon
                        side={40}
                        clicked={options.allUsersShowed}
                    />
                </div>
            </Tooltip>

            <div
                id="extra-room-buttons"
                ref={extraButtonsRef}
            >
                
                <SavedRecordings
                    {...{
                        userData,
                    }}
                />
                
                <Tooltip title="Chat">
                    <IconButton
                        onClick={() => toggleChat()}
                        className={`chat-button ${chatOpened ? "clicked": ""}`}
                    >
                        {
                            showNotification &&
                            <div
                                id="point"
                                className=""
                            />
                        }
                        {
                            newMessageCount > 0 &&
                            <div className="new-message-circle">
                                {
                                    newMessageCount > 0 && newMessageCount < 10
                                    ? newMessageCount
                                    : "9+"
                                }
                            </div>
                        }
                        
                        <QuestionAnswer />
                    </IconButton>
                </Tooltip>
                    
                <Tooltip title={
                    fullscreen
                    ? "Unfullscreen"
                    : "Fullscreen"
                }>
                    <IconButton
                        id="fullscreen-app-button"
                        onClick={toggleFullscreenMode}
                        className={fullscreen ? "clicked": ""}
                    >
                        {
                            fullscreen
                            ? <FullscreenExit />
                            : <Fullscreen />
                        }
                    </IconButton>
                </Tooltip>

            </div>

            <div
                id="session-info"
            >
                <Tooltip title={userData?.sessionId}>
                    <div
                        id='room-title'
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        {userData?.sessionId || 'Loading....'}
                    </div>
                </Tooltip>
            </div>

            <div
                className="room-buttons"
                ref={roomButtonsRef}
            >
                <Tooltip title={`Micro is turned ${localUser.audioActive ? "on": "off"}`}>
                    <IconButton
                        color="inherit"
                        className={`navButton ${localUser.audioActive ? "": "clicked"}`}
                        id="navMicButton"
                        onClick={() => {
                            setLocalUser(prevLocalUser => {
                                const {audioActive} = prevLocalUser
                                const result = micStatusChanged(prevLocalUser)
                                
                                const updatedAudioActive = result ? !audioActive: audioActive
                                return {
                                    ...prevLocalUser,
                                    audioActive: updatedAudioActive,
                                }
                            })
                        }}
                    >
                        {
                            localUser.audioActive
                            ? <Mic />
                            : <MicOff color="secondary" />
                        }
                    </IconButton>
                </Tooltip>

                <Tooltip title={`Video is turned ${localUser.videoActive ? "on": "off"}`}>
                    <IconButton
                        color="inherit"
                        className={`navButton ${localUser.videoActive ? "": "clicked"}`}
                        id="navCamButton"
                        onClick={() => {
                            setLocalUser(prevLocalUser => {
                                const {videoActive} = prevLocalUser
                                const result = camStatusChanged(prevLocalUser)
                                
                                const updatedVideoActive = result ? !videoActive: videoActive
                                return {
                                    ...prevLocalUser,
                                    videoActive: updatedVideoActive,
                                }
                            })
                        }}
                    >
                        {
                            localUser.videoActive
                            ? <Videocam />
                            : <VideocamOff color="secondary" />
                        }
                    </IconButton>
                </Tooltip>

                <Tooltip title={`Screenshare`}>
                    <IconButton
                        color="inherit"
                        className={`navButton ${localUser.screenShareActive ? "clicked": ""}`}
                        onClick={() => {
                            const offScreenShare = () => {
                                setLocalUser(prev => ({...prev, screenShareActive: false}))
                            }

                            if (localUser.screenShareActive) {
                                stopScreenShare()
                                .then((result: boolean) => {
                                    if (result)
                                        offScreenShare()
                                })
                                .catch(() => {console.error("stopScreenShare() error")})
                                return
                            }
                            
                            setMediaActive(prev => ({...prev, fullscreen: false}))
                            
                            screenShare(offScreenShare)
                            .then((result: boolean) => {
                                setLocalUser(prev => ({...prev, screenShareActive: result}))
                            })
                            .catch(() => {
                                console.error("screenShare() error")
                            })
                        }}
                    >
                        {
                            localUser.screenShareActive
                            ? <PictureInPicture />
                            : <ScreenShare />
                        }
                    </IconButton>
                </Tooltip>
                
                <Tooltip title="Room record">
                    <IconButton
                        color="inherit"
                        className={`navButton ${record ? 'turn-off': ''}`}
                        onClick={() => {
                            const method = record ? stopRecording : startRecording
                            method()
                            .then((result: boolean) => {
                                if (result)
                                    setMediaActive(prev => ({...prev, record: !record}))
                            })
                            .catch(() => console.error("ERROR_START_SCREEN_SHARE"))

                        }}
                    >
                        {
                            record
                            ? <StopIcon />
                            : <FiberManualRecordIcon />
                        }
                    </IconButton>
                </Tooltip>
                    
                <Tooltip title="Switch video">
                    <IconButton
                        color="inherit"
                        className="navButton"
                        onClick={() => {
                            switchCamera()
                            .then((result: boolean) => {
                                if (result)
                                    console.log("CAMERA_SWITCHED")
                            })
                        }}
                    >
                        <SwitchVideoIcon />
                    </IconButton>
                </Tooltip>
                
                <Tooltip title="Exit room">
                    <IconButton
                        className="navButton"
                        onClick={fullLeave}
                        id="leave-button"
                    >
                        <PowerSettingsNew />
                    </IconButton>
                </Tooltip>

            </div>
            
            <ChatComponent
                close={toggleChat}
                messageReceived={checkNotification}

                {...{
                    setLocalUser,
                    localUser,
                    userData,
                    setNewMessageCount,
                }}
            />
        </div>
    );
}
