import { Request } from "../../base";
import UserModel from "../../models/user-model";
import { useUserData } from "../App";


function useFilter() {
    var [userData, setUserData] = useUserData()

    async function fetchFilterData(url: string){
        /*let result = "";
        const decoder = new TextDecoder("utf-8");
        const response = await fetch(url);

        const readableStream = response.body;
        if (!readableStream)
            return console.error('readableStream is undefined')
        const reader = readableStream.getReader();
        
        const isLoop = true
        while (isLoop) {
            const { done, value } = await reader.read();
            if (done) break;

            result += decoder.decode(value, {stream:true});
            const lines = result.split('\n');
            result = lines.pop() || '';

            lines.forEach((line) => {
                try{
                    const data = JSON.parse(line)
                    setUserData && setUserData(prev => ({...prev, filterData: data}))
                }
                catch(e){
                    console.log(line);
                }
            })
        }
        */
        const filterSocket = new WebSocket(url);
        setUserData && setUserData(prev => ({...prev, filterSocket: filterSocket}));
        filterSocket.onmessage = function(event) {
            try{
                const data = JSON.parse(event.data);
                setUserData && setUserData(prev => ({...prev, filterData: data}))
            }
            catch(error){
                console.error(error)
            }
        }

    }

    const applyFilter = async (user: UserModel) => {
        const streamManager = user?.streamManager
        const stream = streamManager?.stream
        const streamId = stream?.streamId

        if (!stream || !streamId)
            return console.warn("stream and streamId for applyFilter not undefined")
        
        
        const command = `PyTruth streamId=${streamId}`;

        stream.applyFilter("GStreamerFilter", {command})
        .then(() => {
            console.log("Filter applyed");
            const url = Request.getWssUrl(`/filter/${streamId}`);
            fetchFilterData(url);
        })
        .catch((error: any) => {
            console.error(error);
        });
    }

    
    const removeFilter = async (user: UserModel) => {
        const streamManager = user?.streamManager
        const filterSocket = userData.filterSocket
        const stream = streamManager?.stream
        const streamId = stream?.streamId
        stream.removeFilter();
        if (!stream)
            return console.error("stream is undefined for removeFilter from StreamComponent")
        if (!streamId)
            return console.error("streamId is undefined for removeFilter from StreamComponent")
        if (filterSocket != undefined){
            filterSocket.close();
            console.log("Socket closed");
        }
        
        /* 
        const url = `/filter/stop/${streamId}`
        await Request.get({ url })
        */ 
    }

    return {
        removeFilter,
        applyFilter,

    }
}

export default useFilter;
