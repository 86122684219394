import React, { HTMLAttributes } from 'react'
import FilterLineChart from '../../../stream/FilterLineChart/FilterLineChart'
import { processFieldName, textFieldNames } from '../../../stream/OvVideo'
import SoundChart from '../SoundChart/SoundChart'
import UserModel from '../../../../models/user-model'
import { useUserData } from '../../../App'

interface FilterInfoProps extends HTMLAttributes<HTMLDivElement> {
    user: UserModel,
}

export default function FilterInfo({
    user,
    ...props
}: FilterInfoProps) {
    var [userData, setUserData] = useUserData()

    return <div {...props}>
        <FilterLineChart />
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '20px'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '5px',
                    marginLeft: '20px',
                    color: 'white',
                    textAlign: 'left',

                }}
            >
                {
                    textFieldNames.map((name, index) => {
                        const keyName = processFieldName(name)
                        const value = (userData.filterData as any)[name]
                            || "⏳"
                        return <div key={index}>
                            {keyName}: {value}
                        </div>
                    })
                }
            </div>
        </div>
        <div>
            <SoundChart
                user={user}
            />
        </div>
    </div>
}