import { Session, SignalEvent, Stream, StreamEvent, Subscriber } from "openvidu-browser";
import UserModel, { getDefaultUserData, userIsAdmin } from "../../../models/user-model";
import { UseConnectProps, setVoiceHandler } from "./useConnect";
import { useState } from 'react'
import { useRouteLoaderData } from "react-router-dom";
import { List } from "@material-ui/core";
import RoomEvents from "../../../enum/room_events";
import { Room } from "@material-ui/icons";


export interface UseSubscribeProps extends UseConnectProps {
    checkSomeoneShareScreen: any,
}


export const defineUndefinedValue = (prevValue: any, newValue: any) => {
    return newValue !== undefined ? newValue: prevValue
}
export function appendList(list: any[], newElement: any) {
    return [...list, newElement]
}

export default function useSubscribe({
    userData,
    options,

    setUserData,
    updateLayout,

    localUser,
    setLocalUser,

    sendSignalUserChanged,
    checkSomeoneShareScreen,

    fullLeave,
    
    ...props
}: UseSubscribeProps) {
    
    const updateSubscribers = (
        fromSession: Session | null=null,
        prevSubs: UserModel[],
        newSubs: UserModel[] = [],
    ) => {
        var updatedSubs = [...prevSubs, ...newSubs]

        var isAdmin = userIsAdmin(userData.role)
        
        var signalData = {
            isAudioActive: localUser.audioActive,
            isVideoActive: localUser.videoActive,
            nickname: userData.userName,
            isScreenShareActive: localUser.screenShareActive,
            muteForAll: localUser.mutedForAll,
            isAdmin,
        }
        setTimeout(() => {
            sendSignalUserChanged(signalData, fromSession)
            updateLayout()
        }, 1000)

        return updatedSubs
    }

    var createSubscriber = (
        session: Session,
        stream: Stream
    ) => {
        if (!session)
            return console.error("session is undefined for createSubscriber")
        const subscriber = session.subscribe(stream, undefined);
        
        subscriber.on('streamPlaying', () => {
            checkSomeoneShareScreen()
            var {video} = subscriber.videos[0]
            var {classList} = video.parentElement as HTMLElement
            classList.remove('custom-class');
        });
        return subscriber
    }
    
    const subscribeToStreamCreated = (session: Session) => {
        
        session.on('streamCreated', (event: any) => {
            setUserData(prev => {
                var {stream} = event
                var {connection} = stream
                var {connectionId, data} = connection

                var nickname = data.split('%')[0];
                var { clientData: userNickname } = JSON.parse(nickname)

                var subscriber = createSubscriber(session, stream)
                var newUser = {
                    ...getDefaultUserData("remote", false),
                    nickname: userNickname,
                    connectionId,
                    streamManager: subscriber,
                }
                
                const prevSubs =
                    prev.subscribers
                    .filter(sub => sub.nickname !== userNickname)
                            
                const updatedSubs = updateSubscribers(session, prevSubs, [newUser])
                setVoiceHandler(subscriber, userNickname)

                return {
                    ...prev,
                    subscribers: updatedSubs,
                }
            })
            
        });
    }

    const deleteSubscriber = (
        subs: UserModel[],
        stream: Stream
    ) => {
        const prevSubs = subs
        if (prevSubs.length === 0)
            return subs

        const subscribers = prevSubs.filter((user) => {
            const otherStream = user?.streamManager?.stream
            return otherStream !== stream
        })
        return subscribers
    }

    const subscribeToStreamDestroyed = (
        session: Session,
    ) => {

        const onStreamDestroyed = (event: StreamEvent) => {
            setUserData(prev => {
                const { stream } = event
                const prevSubs = prev.subscribers
                const updatedSubs = deleteSubscriber(prevSubs, stream)

                setTimeout(() => {
                    event.preventDefault();
                    updateLayout();
                }, 1000)
                
                return {...prev, subscribers: updatedSubs}
            })
        }
        
        session.on('streamDestroyed', onStreamDestroyed)
    }

    const roomEventHandler = (
        payloadData: any,
        session: Session,
    ) => {
        var {event, nickname} = payloadData

        const shouldProceed =
            (localUser.nickname === nickname) ||
            (userData.userName === nickname)
        if (!shouldProceed)
            return

        var signal = (value: any) => sendSignalUserChanged(value, session)
        
        if (event === RoomEvents.MUTE_FOR_ALL) {
            
            setLocalUser(p => {
                var newValue = !(p.mutedForAll)
                signal({
                    mutedForAll: newValue,
                })
                return {
                    ...p,
                    mutedForAll: newValue,
                }
            })
            
        }
        else if (event === RoomEvents.KICK) {
            var message = "You was kicked from room"
            window.alert(message)
            console.warn(message)

            fullLeave()
        }
        
    }

    const subscribeToUserChanged = (session: Session) => {
        
        // USER_CHANGED_(SENDED_NEW_EVENT_DATA)
        
        const onUserChanged = ({from, data,}: SignalEvent) => {
            setUserData(prev => {
                const {subscribers} = prev
                const fromConnectionId = from?.connectionId

                if (!fromConnectionId || !data) {
                    if (!fromConnectionId)
                        console.error("User was changed and connectionId is undefined")
                    if (!data)
                        console.error("User was changed and data is undefined")

                    return prev
                }

                let payloadData: any;

                try {
                    payloadData = JSON.parse(data)
                } catch {
                    console.error(`
                        Cannot parse json from userChanged signal event:

                        ${data}
                    `)
                    return prev
                }
                
                const {
                    isAudioActive, isVideoActive,
                    nickname, isScreenShareActive,
                    isAdmin,
                    mutedForAll,
                    event,
                } = payloadData;

                const isEventHandler = event !== undefined
                if (isEventHandler) {
                    setTimeout(() => {
                        roomEventHandler(payloadData, session)
                    }, 1)
                    return prev
                }

                
                const payload: any = {}
                if (isAdmin !== undefined) {
                    payload.isAdmin = isAdmin
                }
                if (isAudioActive !== undefined)
                    payload.audioActive = isAudioActive
                if (isVideoActive !== undefined)
                    payload.videoActive = isVideoActive
                if (nickname !== undefined)
                    payload.nickname = nickname
                if (isScreenShareActive !== undefined)
                    payload.screenShareActive = isScreenShareActive
                if (mutedForAll !== undefined)
                    payload.mutedForAll = mutedForAll
                
                const updatedSubscribers = subscribers.map((user: UserModel) => {
                    const userConnectionId = user?.connectionId

                    if (userConnectionId !== fromConnectionId)
                        return user

                    const updatedUser = {
                        ...user,
                        ...payload,
                    }
                    return updatedUser
                })
                
                setTimeout(() => {
                    checkSomeoneShareScreen(updatedSubscribers)
                }, 1000)

                return {
                    ...prev,
                    subscribers: updatedSubscribers
                }
            })
        }
        
        session.on('signal:userChanged', onUserChanged);
    }
    

    return {
        updateSubscribers,
        subscribeToStreamCreated,
        deleteSubscriber,
        subscribeToStreamDestroyed,
        subscribeToUserChanged,
    }
}