import React, { forwardRef, InputHTMLAttributes, KeyboardEvent, MouseEvent, MouseEventHandler } from 'react';
import './input.scss';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string,
    onMouseDown?: (event: any) => void | null,
}


export default function Input({
    className="",
    ...props
}: InputProps) {
    

    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        const { key, currentTarget, } = event
        const { onKeyDown } = props
        
        if (onKeyDown)
            onKeyDown(event)

        if (key === "Escape") {
            currentTarget.blur()
        }
    }

    const inputProps = {
        ...props,
        onKeyDown,
        className: `standart ${className}`,
        spellCheck: false,
    }

    return (
        <input
            {...inputProps}
        />
    )
}

