import React from 'react';


export default function UsersIcon({
    side=24,
    clicked=false,
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={`${side}px`} height={`${side}px`}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M13 20V18C13 15.2386 10.7614 13 8 13C5.23858 13 3 15.2386 3 18V20H13ZM13 20H21V19C21 16.0545 18.7614 14 16 14C14.5867 14 13.3103 14.6255 12.4009 15.6311M11 7C11 8.65685 9.65685 10 8 10C6.34315 10 5 8.65685 5 7C5 5.34315 6.34315 4 8 4C9.65685 4 11 5.34315 11 7ZM18 9C18 10.1046 17.1046 11 16 11C14.8954 11 14 10.1046 14 9C14 7.89543 14.8954 7 16 7C17.1046 7 18 7.89543 18 9Z"
                stroke={clicked ? "white": "gray"}
                style={{
                    transition: '.2s stroke'
                }}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}