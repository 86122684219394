import React, { useEffect, useRef, useMemo, useState} from 'react';
import UserModel from '../../models/user-model';
import { SignalEvent } from 'openvidu-browser';
import UserIcon from '../../base/svg/user';
import VoiceSoundIcon from '../../base/svg/voice-sound/voice_sound';
import { useUserData } from '../App';
import {
  Category,
  DrawingUtils,
  FaceLandmarker,
  FaceLandmarkerResult,
  FilesetResolver
} from '@mediapipe/tasks-vision';

interface OvVideoProps {
    user: UserModel,
    mutedSound: boolean,
    isVideoDefined: boolean,
    setIsVideoDefined: any,
    
}

export const alertNames = [
    ["wink", "gray"],
    ["true_smile", 'greenyellow'],
    //["truth", 'rgb(6,246,12)'],

    ["stressed", 'orange'],
    ["attention", 'red'],
    ["probably_lying", 'rgb(251,137,3)'],
    ["face_touching", "rgb(2,120,253)"],
    
]
export const textFieldNames = [
    "expression",
    "dominant_emotion",
    "gaze_direction",
]
export const processFieldName =
    (name: string) => name[0].toUpperCase() + name.slice(1)
        .replace("_", " ")

export const wasmFolderPath = 'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.10/wasm'

export default function OvVideoComponent({
    user,
    mutedSound,
    isVideoDefined,
    setIsVideoDefined,
    
    ...props
}: OvVideoProps) {
    
    const videoRef = useRef<HTMLVideoElement>(null)
    const userIconRef = useRef(null)
    //Inject
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const contextRef = useRef<CanvasRenderingContext2D | null>(null)
    const [face, setFace] = useState<FaceLandmarker | null>()
    
    
    //End Inject

    var [userData, setUserData] = useUserData()

    var filter = userData.isFilterTurnedOn(user)

    var isLocal = user.type === 'local'

    //inject
    const sendToMediaPipe = async () => {
      if (videoRef.current) {
            const startTimeMs = performance.now();
            if (face != null) {
              const results = face.detectForVideo(videoRef.current, startTimeMs);
            onResults(results);
            }
            requestAnimationFrame(sendToMediaPipe);
        }
    };

    const getFace = async () => {
        const filesetResolver = await FilesetResolver.forVisionTasks(
            wasmFolderPath
        )
        return await FaceLandmarker.createFromOptions(filesetResolver, {
        baseOptions: {       
            modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
            delegate: "GPU" //fix asap when Google update model
        },
        runningMode: 'VIDEO',
        outputFaceBlendshapes: true,
        minFaceDetectionConfidence: 0.75,
        minFacePresenceConfidence: 0.75,
        });
    }

    useEffect(() => {
        if(!filter || isLocal)
            return
        if (face !== undefined) {
            sendToMediaPipe();
        } else {
        getFace().then((face) => {
            setFace(face);
        });
        }
    }, [face]);



  const onResults = (results: FaceLandmarkerResult) => {
    if (canvasRef.current && contextRef.current) {

      const drawingUtils = new DrawingUtils(contextRef.current);
      contextRef.current.save();
      contextRef.current.clearRect(
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
      );

      if (results.faceLandmarks) {
        for (const landmarks of results.faceLandmarks) {
          drawingUtils.drawConnectors(
              landmarks,
              FaceLandmarker.FACE_LANDMARKS_TESSELATION,
              {color: "#0F0",lineWidth: 0.5, radius: 1}
          );
          drawingUtils.drawConnectors(
              landmarks,
              FaceLandmarker.FACE_LANDMARKS_FACE_OVAL,
              {color: "#0F0",lineWidth: 2, radius: 1}
          );
          //INNER LIPS VERTICES
          drawingUtils.drawConnectors(
              landmarks,
              [{start: 78, end: 191},
                {start: 191, end: 80},
                {start: 80, end: 81},
                {start: 81, end: 82},
                {start: 82, end: 13},
                {start: 13, end: 312},
                {start: 312, end: 311},
                {start: 311, end: 310},
                {start: 310, end: 415},
                {start: 415, end: 308},
                {start: 308, end: 324},
                {start: 324, end: 318},
                {start: 318, end: 402},
                {start: 402, end: 317},
                {start: 317, end: 14},
                {start: 14, end: 87},
                {start: 87, end: 178},
                {start: 178, end: 88},
                {start: 88, end: 95},
                {start: 95, end: 78}],
              {lineWidth: 1, radius: 2}
          );
          drawingUtils.drawConnectors(
              landmarks,
              FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
              {color: "#F0F", lineWidth: 1, radius: 1}
          );
          drawingUtils.drawConnectors(
              landmarks,
              FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
              {color: "#F0F",lineWidth: 1, radius: 1}
          );
          //draw lifts
          //left
          drawingUtils.drawConnectors(
              landmarks,
              [{start: 46, end: 117}],
              {color: "#00F",lineWidth: 1, radius: 1}
          );
          //right
          drawingUtils.drawConnectors(
              landmarks,
              [{start: 276, end: 346}],
              {color: "#00F",lineWidth: 1, radius: 1}
          );
          //draw orbitalis
          //left
          drawingUtils.drawConnectors(
              landmarks,
              [{start: 53, end: 52},
                {start: 52, end: 65}, 
                {start: 65, end: 28},  
                {start: 28, end: 27}, 
                {start: 27, end: 29}, 
                {start: 29, end: 53} ],
              {color: "#F00",lineWidth: 1, radius: 1}
          );
          //right
          drawingUtils.drawConnectors(
              landmarks,
              [{start: 295, end: 282},
                {start: 282, end: 283}, 
                {start: 283, end: 259},  
                {start: 259, end: 257}, 
                {start: 257, end: 258}, 
                {start: 258, end: 295} ],
              {color: "#F00",lineWidth: 1, radius: 1}
          );
        }
      }
      /* Hide holistic
      if(results.poseLandmarks){
        for (const landmarks of results.poseLandmarks) {
            drawingUtils.drawConnectors(
              landmarks,
              HolisticLandmarker.POSE_CONNECTIONS,
              {color: "#11cfd9", lineWidth: 0.5}
          );
        }
      }
      if(results.leftHandLandmarks){
        for (const landmarks of results.leftHandLandmarks) {
            drawingUtils.drawLandmarks(
              landmarks,
              {color: "#4c1679", radius: 0.5}
            );
            drawingUtils.drawConnectors(
              landmarks,
              HolisticLandmarker.HAND_CONNECTIONS,
              {color: "#fa2c78", lineWidth: 0.5}
            );
        }
      }
      if(results.rightHandLandmarks){
        for (const landmarks of results.rightHandLandmarks) {
            drawingUtils.drawLandmarks(
              landmarks,
              {color: "#0cdc4c", radius: 0.5}
            );
            drawingUtils.drawConnectors(
              landmarks,
              FaceLandmarker.HAND_CONNECTIONS,
              {color: "#0875fa", lineWidth: 0.5}
            );
        }
      }*/
      contextRef.current.restore();
    }

  }
    //end inject

    const videoTurnedOn = useMemo(() =>
        user.videoActive && isVideoDefined,
        [
            user,
            isVideoDefined,
        ]
    )
    
    const handleUserChangedHandler = (user: UserModel) => {
        return (event: SignalEvent) => {
            const {data} = event
            
            const {streamManager} = user
            const video = videoRef.current

            if (data === undefined) {
                return console.error("error, data is undefined for VideoComponent")
            }
            const { isScreenShareActive } = JSON.parse(data);
            if (isScreenShareActive !== undefined && streamManager) {
                streamManager.addVideoElement(video);
            }
        };
    }

    useEffect(() => {
        var streamManager = user?.streamManager
        var session = streamManager?.session
        var video = videoRef.current
        
        if (video && streamManager) {
            streamManager.addVideoElement(video);
            setIsVideoDefined(true)
        }
        
        if(filter || !isLocal){
            if (video && canvasRef.current) {
              console.log('rendering');
              contextRef.current = canvasRef.current.getContext('2d');
            }
        }

        if (streamManager && session) {
            
            var userChangedHandler = handleUserChangedHandler(user)
            
            session.on('signal:userChanged', userChangedHandler);
            setIsVideoDefined(true)

            return () => {
                session.off('signal:userChanged', userChangedHandler);
            }
        }
        
    }, [user, videoRef, () => videoRef.current])

    
    const borderColor = useMemo(() => {
        const estimated_deception = parseFloat(
            (userData.filterData as any).weighted_estimated_deception || 0)
        
        if (!isVideoDefined || !filter)
            return "rgba(1,1,1,0)"
        
        if (estimated_deception > 0.6)
            return 'red'
                
        if (estimated_deception > 0.4)
            return "yellow"
        
        return "green"

    }, [userData, () => userData.filterData])
    
    
    return (<>
        <VoiceSoundIcon
            style={{
                position: 'absolute',
                right: 'auto',
                left: '10px',
                top: '10px',
                zIndex: '2001',
            }}
        />
        
        <div
            style={{
                width: "100%",
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '990',
                position: 'absolute',
                display: 'flex',
                opacity: "1",//!videoTurnedOn ? "1": "0",
                transition: 'opacity .5s',
                overflow: 'hidden'
            }}
            ref={userIconRef}
        >
            <UserIcon />
        </div>
        
        <div
            style={{
                position: 'relative',
                zIndex: '1001',
                height: '100%'
            }}
        >
            <video
                autoPlay={true}
                className={`participant-video-element defined-${isVideoDefined}`}
                ref={videoRef}
                style={{
                    filter: isVideoDefined ? "": 'blur(5px)',
                    outline: `4px solid ${borderColor}`,
                    outlineOffset: '-2px',
                    width: '100%',
                    opacity: videoTurnedOn ? '1': '0',
                    transition: 'opacity .5s',
                }}
                muted={
                    isLocal
                    ? false
                    : (mutedSound || user.mutedForAll)
                }
                onLoadedMetadata={() => {
                    setIsVideoDefined(true)
                }}
            />
            <canvas ref={canvasRef}
                style={{
                    filter: isVideoDefined ? "": 'blur(5px)',
                    width: '100%',
                    opacity: videoTurnedOn ? '1': '0',
                    transition: 'opacity .5s',
                }}

            />

            <div
                className="filterAlerts"
            >
                {
                    useMemo(() => alertNames.map(([name, backgroundColor], index) => {
                        if (!filter)
                            return
                        const value = (userData.filterData as any)[name] 
                        if (value !== "True")
                            return null

                        const textName = processFieldName(name)

                        return <div
                            className="alertName"
                            style={{
                                backgroundColor,
                            }}
                            key={index}
                        >
                            {textName}
                        </div>
                    }), [userData, () => userData.filterData])
                }
            </div>
        </div>
    </>);
}
 