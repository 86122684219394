import { useContext } from "react";
import AlertContext from "./AlertContext";


function useAlertContext() {
    const context = useContext(AlertContext);
    return context
}

export default useAlertContext;
