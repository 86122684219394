import React, { useEffect, useState } from 'react';

import './VideoRoom.scss';


import Toolbar from '../toolbar/Toolbar';
import useVideoRoom from '../hooks/useVideoRoom/useVideoRoom';

import { UserDataProps } from '../App';
import VideoPeersBlock, { UserActionContext, useUserActionContext } from './video-peers-block/VideoPeersBlock';
import UserRoles from '../../enum/user_roles';
import RoomEvents from '../../enum/room_events';
import UserModel from '../../models/user-model';


export function getOpenViduLayoutOptions({
    fixedRatio=false,
}) {
    return {
        maxRatio: 3 / 2, // The narrowest ratio that will be used (default 2x3)
        minRatio: 9 / 16, // The widest ratio that will be used (default 16x9)
        fixedRatio, // If this is true then the aspect ratio of the video is maintained and minRatio and maxRatio are ignored (default false)
        bigClass: 'OV_big', // The class to add to elements that should be sized bigger
        bigPercentage: 0.8, // The maximum percentage of space the big ones should take up
        bigFixedRatio: false, // fixedRatio for the big ones
        bigMaxRatio: 3 / 2, // The narrowest ratio to use for the big elements (default 2x3)
        bigMinRatio: 9 / 16, // The widest ratio to use for the big elements (default 16x9)
        bigFirst: true, // Whether to place the big one in the top left (true) or bottom right
        animate: true, // Whether you want to animate the transitions
    }
}

type voidFunc = () => void
interface VideoRoomProps extends UserDataProps {
    leaveSession: voidFunc,
    fullLeave: voidFunc,
}

export type OptionsType = {
    updated: boolean,
        
    localUserAccessAllowed: boolean,
    showExtensionDialog: boolean,
    messageReceived: boolean,

    allUsersShowed: boolean,
};
export type SetOptionsType = React.Dispatch<React.SetStateAction<OptionsType>>

export interface VideoRoomOptionsProps {
    options: OptionsType,
    setOptions: SetOptionsType,
}


export default function VideoRoom({
    userData,
    setUserData,

    leaveSession,
    fullLeave,
    ...props
}: VideoRoomProps ) {
    
    const [options, setOptions] = useState<OptionsType>({
        updated: false,
        
        localUserAccessAllowed: false,
        showExtensionDialog: false,
        messageReceived: false,

        allUsersShowed: false,
    })

    const {
        layout,
        localUser, setLocalUser,

        updateLayout,
        checkSize,
        joinSession,
        
        switchCamera,
        toggleChat,
        
        screenShare,
        stopScreenShare,
        camStatusChanged,
        micStatusChanged,
        toggleFullscreen,
        startRecording,
        stopRecording,
        closeDialogExtension,
        nicknameChanged,
        checkNotification,
        sendSignalUserChanged,
        
    } = useVideoRoom({
        userData,
        options,

        setUserData,
        setOptions,
        fullLeave,
    })

    const onbeforeunload = () => {
        fullLeave()
    }

    useEffect(() => {
        
        
        const defaultOvOptions = getOpenViduLayoutOptions({})
        const layoutElement = document.getElementById('layout')

        layout.initLayoutContainer(layoutElement, defaultOvOptions);
        updateLayout()
        
        window.addEventListener('beforeunload', onbeforeunload);
        window.addEventListener('resize', updateLayout);
        window.addEventListener('resize', checkSize);

        try {
            joinSession();
        } catch(error) {
            console.error(error)
            alert(`${error}`)
        }

        
        
        return () => {
            window.removeEventListener('beforeunload', onbeforeunload);
            window.removeEventListener('resize', updateLayout);
            window.removeEventListener('resize', checkSize);
        }
    }, [])

    
    const {role: currentRole, } = userData
    const mode = currentRole === UserRoles.MODERATOR
    
    const {
        messageReceived: showNotification,
        showExtensionDialog,
    } = options
    
    const userStreamIsDefined = Boolean(localUser)
    
    const payload = {
        userData,
        options,

        mode,
        switchCamera,
        leaveSession,
        toggleChat,

        screenShare,
        stopScreenShare,
        camStatusChanged,
        micStatusChanged,
        toggleFullscreen,
        startRecording,
        stopRecording,
        fullLeave,

        showNotification,
        
        userStreamIsDefined,
        checkNotification,
        nicknameChanged,
        showExtensionDialog,
        cancelClicked: closeDialogExtension,
        sendSignalUserChanged,

        localUser, setLocalUser,
        setUserData,
        setOptions,
        updateLayout: () => {
            updateLayout();
            checkSize();
            
            const event = new Event("resize");
            window.dispatchEvent(event);
        },
    } as any
    
    const className = `container ${userStreamIsDefined ? "defined": ""}`
    

    const kickUser = (user: UserModel) => {
        const nickname = user?.nickname
        
        if (!nickname)
            return console.error(`nickname is undefined for kick: ${nickname}`)
        
        const event = RoomEvents.KICK
        sendSignalUserChanged({ event, nickname, })
    }

    const muteForAll = (user: UserModel) => {
        sendSignalUserChanged({
            event: RoomEvents.MUTE_FOR_ALL,
            nickname: user?.nickname,
        }, userData.session)
    }

    const toggleFilter = (user: UserModel) => {
        
        setUserData(prev => {
            var isUserFiltered = prev.filterUsername === user.nickname

            return {
                ...prev,
                filterUsername: isUserFiltered ? "": user.nickname,
            }
        })        
    }

    return (<UserActionContext.Provider value={{
        kickUser,
        muteForAll,
        toggleFilter,
    }}>
        <div
            className={className}
            id="container"
        >
            <Toolbar
                {...payload}
            />
            
            <VideoPeersBlock
                {...payload}
            />
        </div>
    </UserActionContext.Provider>);
}
