const fullscreenElementNames = [
    "fullscreenElement",
    "webkitFullscreenElement",
    "mozFullScreenElement",
    "msFullscreenElement",
]

const fullscreenMethodNames = [
    "requestFullscreen",
    "msRequestFullscreen",
    "mozRequestFullScreen",
    "webkitRequestFullscreen",
]
const unFullscreenMethodNames = [
    "exitFullscreen",
    "msExitFullscreen",
    "mozCancelFullScreen",
    "webkitExitFullscreen"
]
type ElementType = HTMLElement | any

function getExistedAttr(
    element: ElementType,
    attrNames: string[],
) {
    for (const attrName of attrNames) {
        const attribute = element[attrName]
        if (attribute)
            return attribute
    }
    return undefined
}
export const isFullscrened = (): boolean => {
    const fullscreened = Boolean(
        !document.fullscreenElement &&
        !(document as any).webkitFullscreenElement &&
        !(document as any).mozFullScreenElement &&
        !(document as any).msFullscreenElement
    )
    return fullscreened
}


export function toggleFullscreenElement(element: ElementType) {
    
    const fullscreened = isFullscrened()

    const methodPayload: [ElementType, string[]] =
        fullscreened
        ? [element, fullscreenMethodNames]
        : [document, unFullscreenMethodNames]

    const [currentElement, currentMethodNames] = methodPayload
    
    const method = getExistedAttr(...methodPayload)
    
    if (!method) {
        console.error("Fullscreen method not available.");
        return undefined;
    }

    try {
        method.call(currentElement);
        
    } catch (error) {
        console.error("Error toggling fullscreen:", error);
        return undefined;
    }
}
