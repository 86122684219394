
import App from './components/App';
import React, { useState, } from 'react';
import './index.scss';
import ReactDOM from 'react-dom/client';
import { AlertContext, AlertModalWindow } from './base/alert';


function RootApp() {
    const [alertText, setAlertText] = useState("")
    const [alertVisible, setAlertVisible] = useState(false)

    return <AlertContext.Provider value={{
        setAlertText,
        alertText,
        visible: alertVisible,
        setVisible: setAlertVisible,
    }}>
        <App />
        <AlertModalWindow>
            {alertText}
        </AlertModalWindow>
    </AlertContext.Provider>
}

const rootElement = document.querySelector('#root') as HTMLElement
const root = ReactDOM.createRoot(rootElement)

root.render(<RootApp />)
