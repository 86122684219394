import React from "react"

export default function PushNotifyIcon({
    fill="white"
}) {
    return <svg
        className="push-notify-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="24px" height="24px"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M9.17154 7.28839L7.75732 8.7026L11.9999 12.9453L16.2426 8.70263L14.8284 7.28842L12 10.1168L9.17154 7.28839Z"
            fill={fill}
        />
        <path d="M8.00001 14H16V16H8.00001V14Z" fill={fill} />
        <path
            fillRule="evenodd" clipRule="evenodd"
            d="M1 5C1 2.79086 2.79086 1 5 1H19C21.2091 1 23 2.79086 23 5V19C23 21.2091 21.2091 23 19 23H5C2.79086 23 1 21.2091 1 19V5ZM5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3Z"
            fill={fill}
        />
    </svg>
}