export const IS_LOCAL = true
export const DEFAULT_SESSION_ID = "DEFAULT_ROOM_NAME"

//hardcode there if local
export const APPLICATION_SERVER_URL = "demo.lietome.ai"
export const OPENVIDU_SECRET = 'MY_SECRET';

export const OPENVIDU_EXTENSION_URL = 'https://chrome.google.com/webstore/detail/openvidu-screensharing/lfcgfepafnobdloecchnfaclibenjold'
export const SIGNAL_TYPES = {
    userChanged: 'userChanged'
}

