import React, {useState, useEffect, useRef} from 'react';
import { LineChart, Line, XAxis, YAxis, Legend, Tooltip } from 'recharts';
import { StreamOptionsType } from '../StreamComponent';
import { useUserData } from '../../App';


export const chartFieldNames = [
  'weighted_estimated_deception',
  "angry",
  "scared",
  "happy",
  "sad",
  "surprised",
  "neutral",
]

export function flatCapitalize(field: string): string {
  const current = field.replace("_", " ")
  const capitalized = current[0].toUpperCase() + current.slice(1)

  return capitalized
}

export const getNeededChartData = (obj: any) => {
  const updatedObj: any = {}
  
  chartFieldNames.forEach(field => {
    const currentField = flatCapitalize(field)
    const newValue = obj[field] || 0
    
    updatedObj[currentField] = parseFloat(newValue)
  })
  return updatedObj
}



const MAX_POINTS = 20

const FilterLineChart = ({
  ...props
}) => {
  const [filterChartData, setFilterChartData] = useState<any[]>([])
  var [userData, setUserData] = useUserData()
  var predFilterDataRef = useRef({})

  useEffect(() => {
    setFilterChartData(prev => {
      if (predFilterDataRef.current === userData.filterData)
        return prev
      predFilterDataRef.current = userData.filterData

      const newData = getNeededChartData(userData.filterData)

      const currentPrev =
        MAX_POINTS <= prev.length
        ? prev.slice(1)
        : prev

      return ([...currentPrev, newData])
    })
  }, [() => JSON.stringify(userData.filterData)])

  const colors = [
    'red', 'blue', 'yellow',
    'green', 'white', 'blueviolet', 'gray']
  
  return (
    <LineChart
      width={300} height={200}
      style={{
        paddingTop: '10px',
        marginLeft: 'auto',
        marginRight: 'auto'
      }}
      data={filterChartData}
    >
      <XAxis dataKey="name" />
      <YAxis />
      <Legend />
      <Tooltip />
      {
        chartFieldNames.map((name, index) => {
          return <Line
            key={index}
            type="monotone"
            dataKey={flatCapitalize(name)}
            stroke={colors.pop()}
          />
        })
      }
    </LineChart>
  );
};
  

export default FilterLineChart;
