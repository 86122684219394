import React, { useEffect, useState } from "react";
import Dropdown from "../../../base/dropdown/dropdown";
import PushNotifyIcon from "../../../base/svg/push-notify";
import './SavedRecordings.scss'
import { UserDataType } from "../../App";
import DownloadIcon from "../../../base/svg/download";
import { Tooltip } from "@material-ui/core";
import { QuestionAnswer } from "@material-ui/icons";

interface SavedRecordingsProps {
    userData: UserDataType,
}
export function secondsToMinutesAndSeconds(
    seconds: number
): {minutes: number, seconds: number} {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return {
        minutes: parseInt(minutes.toFixed(0)),
        seconds: parseInt(remainingSeconds.toFixed(0))
    };
}

function getWithPreZero(stamp: number) {
    return stamp < 10 ? `0${stamp}` : stamp;
}

function formatTime(
    minutes: number,
    seconds: number,
): string {

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${formattedMinutes}:${formattedSeconds}`;
}
function formatDateTime(createdAt: number): React.ReactNode {
    const date = new Date(createdAt);

    const hours = getWithPreZero(date.getHours());
    const minutes = getWithPreZero(date.getMinutes());
    const seconds = getWithPreZero(date.getSeconds());

    const day = getWithPreZero(date.getDay())
    const month = getWithPreZero(date.getMonth() + 1)
    const year = date.getFullYear()
    
    return <span
        style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            fontFamily: "serif"
        }}
    >
        <span><small>{day}.{month}.{year}</small></span>
        <span><b>{hours}:{minutes}:{seconds}</b></span>
    </span>
}

// const openViduServerIP = "34.118.101.245";
// const credentials = "OPENVIDUAPP:YOUR_SECRET"; // Replace with your actual App ID and secret
// const encodedCredentials = btoa(credentials);

// fetch(`https://${openViduServerIP}/openvidu/api/recordings/`, {
//   method: "GET",
//   headers: {
//     "Authorization": `Basic ${encodedCredentials}`,
//   },
// })
//   .then(response => response.json())
//   .then(data => console.log(data))
//   .catch(error => console.error("Error:", error));


// {
//     "id": "DEFAULT_ROOM_NAME",
//     "object": "recording",
//     "name": "DEFAULT_ROOM_NAME",
//     "outputMode": "INDIVIDUAL",
//     "sessionId": "DEFAULT_ROOM_NAME",
//     "createdAt": 1700847121670,
//     "size": 295859,
//     "duration": 5.188,
//     "url": "https://34.118.101.245/openvidu/recordings/DEFAULT_ROOM_NAME/DEFAULT_ROOM_NAME.zip",
//     "hasAudio": true,
//     "hasVideo": true,
//     "status": "ready"
// }


export default function SavedRecordings({
    userData,
}: SavedRecordingsProps) {
    const {recordings} = userData;
    
    return (
        <Dropdown
            WrapButtonElement={({children}: any) => <Tooltip title="Recordings">{children}</Tooltip>}
            buttonElement={<PushNotifyIcon />}
        >
            {recordings.map(({
                    id,
                    createdAt,
                    url,

                    size: bytes,
                    duration,
                }, index) => {
                
                const MBytes = bytes / (1024 * 1024)
                const {minutes, seconds} = secondsToMinutesAndSeconds(duration)
                const formattedTime = formatTime(minutes, seconds)
                const dateString = formatDateTime(createdAt)

                return <div
                    key={index}
                    className="record-block"
                >
                    <div className="record-id">{id}</div>
                    <div className="size">{MBytes.toFixed(1)}&nbsp;MB</div>
                    <div className="time">{formattedTime}</div>
                    <div className="date">{dateString}</div>
                    <div className="download"><a
                        href={url}
                        download={`${id}.rar`}
                    >
                        <button><DownloadIcon /></button>
                    </a>
                    </div>
                </div>
            })}
        </Dropdown>
    )
}
