import { VoidFuncType } from "../../types";
import { UseConnectProps } from "./useConnect";


export type UpdateLayoutFuncType = VoidFuncType

export default function useLayout({
    layout,
    ...props
}: UseConnectProps) {
    const updateLayout: UpdateLayoutFuncType = () => {
        setTimeout(() => {
            try {
                layout.updateLayout()
            } catch(error) {
                console.error(error)
            }
        }, 1);
    }

    return {
        updateLayout
    }
}