
import React, {HTMLProps} from 'react';
import { falseHandler } from '../funcs/handler';
import './context-menu.scss'

interface ContextMenuProps extends HTMLProps<HTMLDivElement> {
    x?: number,
    y?: number,
    visible?: boolean,
    id?: string,
}




export default function ContextMenu({
    visible=false,
    x=0,
    y=0,
    id="context-menu",
    ...props
}: ContextMenuProps) {

    if (!visible)
        return null;

    const style = {
        top: `${y}px`,
        left: `${x}px`,
        ...(props.style || {})
    };

    return (
        <div
            id="context-menu"
            style={style}

            onContextMenu={falseHandler}
            
            {...props}
        >
            {props.children}
        </div>
    )
}
