import { Device, OpenVidu, Publisher } from "openvidu-browser";
import { UseConnectProps, setVoiceHandler } from "./useConnect";
import { toggleFullscreenElement } from "../../../base/funcs/fullscreen";
import UserModel from "../../../models/user-model";








export default function useMedia({
    userData,
    localUser,
    setLocalUser,

    setUserData,
    sendSignalUserChanged,

    ...props

}: UseConnectProps) {
    
    const switchCamera = async () => {
        const {OV, session, currentVideoDevice} = userData
        const currentDeviceId = currentVideoDevice?.deviceId
        if (!currentDeviceId || !OV || !session) {
            const errorData = JSON.stringify({
                currentDeviceId: Boolean(currentDeviceId),
                OV: Boolean(OV),
                session: Boolean(OV)
            }, null, 2)
            console.error(`Error! is not defined some data: ${errorData}`)
            return false
        }
        
        const devices: Device[] = await OV.getDevices()
        const videoDevices = devices.filter((device: Device) => device.kind === 'videoinput');

        if (!(videoDevices && videoDevices.length > 1)) {
            console.error("!(videoDevices && videoDevices.length > 1)")
            return false
        }

        const newVideoDevices =
            videoDevices
            .filter((device: Device) => {
                const {deviceId} = device
                return deviceId !== currentDeviceId
            })

        if (newVideoDevices.length <= 0) {
            console.error("newVideoDevices.length <= 0")
            return false
        }

        const newVideoDevice = newVideoDevices[0]
        const { deviceId } = newVideoDevice

        const {streamManager, audioActive, videoActive} = localUser

        const newPublisher = OV.initPublisher(undefined, {
            audioSource: undefined,
            mirror: true,
            videoSource: deviceId,

            publishAudio: audioActive,
            publishVideo: videoActive,
        });
        
        if (streamManager)
            await session.unpublish(streamManager);

        await session.publish(newPublisher)
        setVoiceHandler(newPublisher, userData.userName)
        
        setLocalUser(user => ({...user, streamManager: newPublisher}))
        setUserData(pred => ({...pred, currentVideoDevice: newVideoDevice }))

        return true
    }

    const toggleFullscreen = () => {
        const {body} = document
        const result = toggleFullscreenElement(body)
        return result
    }

    
    const camStatusChanged = (prevLocalUser: UserModel) => {
        try {
            const {videoActive, audioActive, streamManager} = prevLocalUser

            const newVideoActive = !videoActive
            streamManager.publishVideo(newVideoActive);
            sendSignalUserChanged({
                isVideoActive: newVideoActive,
                isAudioActive: audioActive,
            });
            
            return true
        } catch(error) {
            console.error("VIDEO_CHANGE_ERROR")
            return false
        }
    }

    const micStatusChanged = (prevLocalUser: UserModel) => {
        try {
            const {audioActive, streamManager, videoActive} = prevLocalUser

            const newAudioActive = !audioActive
            streamManager.publishAudio(newAudioActive);
            sendSignalUserChanged({
                isAudioActive: newAudioActive,
                isVideoActive: videoActive,
            });

            return true
        } catch(error) {
            console.error("MICRO_CHANGE_ERROR")
            return false
        }
    }


    return {
        switchCamera,
        toggleFullscreen,
        camStatusChanged,
        micStatusChanged,
    }
}