import React from 'react'
import './LoadingBar.scss'


export default function LoadingBar({
    ...props
}) {
    return (
        <div className="loading-bar-container">
            <div className="loading-bar" />
        </div>
    )
}
